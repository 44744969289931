import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton, List, ListItem, ListItemText,
  Slide,
  Typography
} from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from "react"
import {getAuthenticatedUserId, getRandomUGCCopyType} from "../../libs/utils";
import {KEYS} from "../../libs/company-data";
import {CONTAINER_SX, COPY_TYPES, UGC_COPY_TYPES} from "../../libs/constants";

export default class RunCreativeScript extends React.Component {

  constructor(props) {
    super(props)
    const {inputs, handleChange} = props
    const copyType = inputs[KEYS.COPY_TYPE] === COPY_TYPES.UGC_SURPRISE_ME ? getRandomUGCCopyType() : inputs[KEYS.COPY_TYPE]
    handleChange([KEYS.COPY_TYPE])({
      target: {
        value: copyType
      }
    })

    const payload = {
      copyType,
      companyName: inputs[KEYS.COMPANY_NAME],
      productDefinition: inputs[KEYS.PRODUCT_DEFINITION],
      valueProps: inputs[KEYS.VALUE_PROPS],
      targetAudience: inputs[KEYS.TARGET_AUDIENCE],
      objective: inputs[KEYS.OBJECTIVE],
      followupRequests: inputs[KEYS.FOLLOWUP_REQUESTS],
      // optional fields for UGC
      ...(copyType in UGC_COPY_TYPES ?  {
        durationSeconds: inputs[KEYS.DURATION_SECONDS],
        tone: inputs[KEYS.TONE],
      } : {}),
      // optional fields for landing page
      ...(copyType === COPY_TYPES.LANDING_PAGE_ADVERTORIAL ?  {
        articleType: inputs[KEYS.ARTICLE_TYPE],
        pov: inputs[KEYS.POV],
      } : {}),
    }
    this.state = {
      message: "",
      processing: true,
      payload,
    }
  }

  componentDidMount = () => {
    this.connectWebsocket()
  }

  componentWillUnmount() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
    }
  }

  connectWebsocket = () => {
    const {payload} = this.state
    getAuthenticatedUserId().then(userId => {
      payload.userId = userId;
    });

    this.socket = new WebSocket(process.env.REACT_APP_CREATIVE_WSS_URL);
    console.log(`Submitting websocket payload to ${process.env.REACT_APP_CREATIVE_WSS_URL}: `, payload)

    this.socket.onopen = (event) => {
      console.log('Connected:', event);
      this.setState({
        processing: true,
      })
      this.socket.send(JSON.stringify(payload));
    };

    this.socket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      // ignore messages that are not formatted correctly
      if (!data.hasOwnProperty('type')) {
        return
      }
      const {type, message} = data
      if (type === 'error') {
        console.log('⚠️ Received error message: ', message)
        return
      }

      if (type === 'content') {
        this.setState({
          message: this.state.message + message
        })
      }
    };

    this.socket.onclose = (event) => {
      console.log('Disconnected:', event);
      this.setState({
        processing: false,
      })
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
  }

  handleCopy = () => {
    navigator.clipboard.writeText(this.state.message)
      .then(() => {
        console.log('Text copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }

  handleNextStep = () => {
    const {handleChange, nextStep} = this.props
    handleChange("completionResponse")({
      target: {value: this.state.message},
      callback: nextStep,
    })
  }

  render() {
    const {theme, prevStep, restart, inputs} = this.props

    return (
      <Container id="run-complete-container"
                 maxWidth="md"
                 sx={CONTAINER_SX}>
        <Slide direction="right"
               timeout={400}
               in={true}>
          <div>
            <Box
              display="flex"
              flexDirection="column"
              mt="24px"
              p="24px"
              sx={{
                minHeight: '100px',
                background: theme.palette.primary.white,
                border: "3px solid black",
                borderRadius: "18px",
                position: 'relative'  // Position context for the IconButton
              }}
            >
              <CustomTypography>
                {this.state.message}
              </CustomTypography>
              {this.state.processing === false && (
                <IconButton
                  sx={{
                    p: 0,
                    color: "#36413A",
                    position: 'absolute',  // Absolute positioning for the button
                    top: 24,
                    right: 24,
                  }}
                  onClick={this.handleCopy}
                >
                  <ContentCopyIcon/>
                </IconButton>
              )}
            </Box>
            <Box
              mt="42px"
            >
              <Accordion sx={{  '&.MuiAccordion-root': {
                  border: "2.5px solid black",
                  borderRadius: 18,
                },
              }}>
                <AccordionSummary sx={{padding: "24px"}} expandIcon={<ExpandMoreIcon/>}>
                  <Typography
                    variant="body"
                    color="#292A2A"
                    sx={{
                      fontSize: "20px",
                      fontWeight: 600,
                      paddingLeft: "16px",
                      whiteSpace: 'pre-line',
                      flexGrow: 1,
                    }}>
                    Mr. Oven's Ingredients
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List dense={true}>
                    {dataFields.map(({key, displayName}) => {
                      if (!this.state.payload[key]) return null
                      return (
                        <ListItem key={key}>
                          <ListItemText
                            primary={`${displayName}: ${formatValue(inputs[key], key)}`}
                            primaryTypographyProps={{
                              variant: "body",
                              sx: {
                                fontSize: "20px",
                                fontWeight: 500,
                                paddingRight: "13px",
                                whiteSpace: 'pre-line',
                                color: "#292A2A",
                              }
                            }}
                          />
                        </ListItem>
                      )}
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box display="flex" pt="46px" pb="46px">
              <Box margin="auto">
                <Button variant="outlined"
                        onClick={prevStep}
                        color="primary"
                        sx={{
                          boxShadow: 10,
                          marginRight: "25px",
                          color: theme.palette.primary.black,
                        }}>
                  Back
                </Button>
                <Button variant="contained"
                        onClick={this.handleNextStep}
                        sx={{
                          boxShadow: 10,
                          marginRight: "25px",
                          color: theme.palette.primary.black,
                          backgroundColor: theme.palette.primary.pink,
                        }}>
                  Re-Prompt
                </Button>
                <Button variant="contained"
                        color="secondary"
                        onClick={restart}
                        sx={{
                          boxShadow: 10,
                        }}>
                  Restart
                </Button>
              </Box>
            </Box>
          </div>
        </Slide>
      </Container>
    )
  }
}

const CustomTypography = (props) => {
  return (
    <Typography
      variant="body"
      color="#292A2A"
      sx={{
        fontSize: "20px",
        fontWeight: 500,
        paddingRight: "13px",
        whiteSpace: 'pre-line',
        flexGrow: 1,
      }}
      {...props}
    >
      {props.children}
    </Typography>
  );
};

const formatValue = (value, key) => {
  if (Array.isArray(value)) {
    if (value.length === 0) return 'None';

    if (key === 'followupRequests' && value.every(val => val.hasOwnProperty('followupInput'))) {
      value = value.map(ele => ele.followupInput);
    }
    return "\n• " + value.join("\n• ");
  }
  return value;
};

const dataFields = [
  {key: 'companyName', displayName: 'Company name'},
  {key: 'productDefinition', displayName: 'Product definition'},
  {key: 'valueProps', displayName: 'Value props'},
  {key: 'targetAudience', displayName: 'Target audience'},
  {key: 'objective', displayName: 'Objective'},
  {key: 'durationSeconds', displayName: 'Duration (seconds)'},
  {key: 'tone', displayName: 'Tone'},
  {key: 'copyType', displayName: 'Copy type'},
  {key: 'articleType', displayName: 'Article type'},
  {key: 'pov', displayName: 'POV'},
  {key: 'followupRequests', displayName: 'Follow-up prompts'},
];
