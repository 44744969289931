import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  TablePagination,
  Typography
} from "@mui/material";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import React, {useEffect} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useTheme} from '@mui/material/styles'
import Paper from '@mui/material/Paper';
import {Storage} from "aws-amplify";
import WindowTopElements from "./window-top-elements";

function FileTable({prefix, title, subtitle, handleFileSelect, rowsPerPage, setRowsPerPage}) {
  const [files, setFiles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState(-1);

  const theme = useTheme()

  const getS3Objects = async prefix => {
    setIsLoading(true)
    await Storage.list(prefix)
      .then(objects => {
          const files = objects.results;
          const fileMap = files.map(object => ({
            key: object.key.split(prefix)[1],
            lastModified: object.lastModified.toLocaleString('en-US'),
            size: object.size,
          })).sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified)).slice(0, 100)
          setFiles(fileMap)
          setIsLoading(false)
        }
      )
  }

  useEffect(() => {
    getS3Objects(prefix);
  }, [prefix])

  const downloadFile = async filename => {
    const downloadUrl = await Storage.get(prefix.concat(filename));
    window.location.href = downloadUrl
  }

  // File deletion affordance is currently not enabled
  // handleFileDelete = s3Prefix => {
  //   const selectedFilename = this.props.files[this.state.selectedItemIdx].key
  //   Storage.remove(s3Prefix.concat(selectedFilename), {
  //     level: S3_ACCESS_LEVEL,
  //   }).then(() => {
  //     this.getS3Objects(s3Prefix);
  //   })
  // }
  //
  // handleInputFileDelete = async () => {
  //   this.handleFileDelete(S3_PREFIX.INPUTS)
  // }
  //
  // handleOutputFileDelete = async () => {
  //   this.handleFileDelete(S3_PREFIX.OUTPUTS)
  // }
  //

  // use filename to track selected instead of index, because there can't exist dupe filenames because we're using S3
  const handleClick = (idx, filename) =>  {
    setSelected(idx)
    handleFileSelect(filename)
  }

  const isSelected = idx => selected === idx;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isRowClickable = !!handleFileSelect

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - files.length) : 0;

  return (
    <Container id="file-table-container">
      <Box display="flex" mt="15px">
        <Typography variant="h4" component="h3" margin="auto">
          {title}
        </Typography>
      </Box>
      <Box height={43}>
        {subtitle &&
          <Box display="flex">
            <Typography variant="subtitle1" component="h4" margin="auto">
              {subtitle}
            </Typography>
          </Box>}
      </Box>
      <TableContainer component={Paper} sx={{borderRadius: "12px", border: "2px solid black"}}>
        <WindowTopElements/>
        <Table aria-label="file-table">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Size</TableCell>
              <TableCell align="center">Download</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ?
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}
                           align="center"
                           sx={{
                             color: theme.palette.primary.black,
                             paddingTop: "120px",
                             paddingBottom: "120px"
                           }}>
                  <CircularProgress color="inherit"/>
                </TableCell>
              </TableRow>
            </TableBody> :
            <TableBody>
              {files
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((file, idx) => {
                  const isItemSelected = isSelected(idx);
                  return (
                      <TableRow
                        key={idx}
                        hover={isRowClickable}
                        selected={isItemSelected}
                        onClick={isRowClickable ? () => handleClick(idx, file.key) : null}
                        sx={{
                          '&:last-child td, &:last-child th': {border: 0}
                        }}>
                        <TableCell component="th" scope="row">
                          {file.key}
                        </TableCell>
                        <TableCell align="center">{`${(file.lastModified.toString()).split(',')[0]}`}</TableCell>
                        <TableCell align="center">{`${Math.floor(file.size / 1000)} KB`}</TableCell>
                        <TableCell align="center">
                          <IconButton sx={{p: 0, color: theme.palette.primary.black}}
                                      onClick={() => downloadFile(file.key)}>
                            <DownloadForOfflineIcon/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6}/>
                </TableRow>
              )}
            </TableBody>
          }
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={files.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  )
}

export default FileTable