import React from "react";
import {Box, Button, Container, Slide, TextField, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import {CONTAINER_SX} from "../../libs/constants";

export default function FollowupRequestForm({prevStep, handleChange, inputs, direction}) {
  const theme = useTheme()

  // Go to the previous step if Enter is pressed
  const handleKeyDown = event => {
    if (!!inputs.currentFollowupInput && event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    // Append currentFollowupInput and completionResponse to followupRequests
    handleChange("followupRequests")({
      target: {
        value: [...inputs.followupRequests, {
          followupInput: inputs.currentFollowupInput,
          completionResponse: inputs.completionResponse,
        }]
      },
      // Reset followupInput for the next run
      callback: () => {
        handleChange("currentFollowupInput")({
          target: {value: ""},
          callback: prevStep,
        })
      }
    })
  }

  return (
    <Container id="followup-input-form-container"
               maxWidth="sm"
               sx={CONTAINER_SX}>
      <Slide direction={direction ? "right" : "left"}
             timeout={400}
             in={true}>
        <Box>
          <Box display="flex" pt="51px">
            <Typography variant="h4" component="h1" fontSize={36} fontWeight={500} margin="auto">
              What do you want me to do differently?
            </Typography>
          </Box>
          <Box display="flex" pt="24px">
            <Box m="auto" component="form">
              <TextField id="followup-inputs-textfield"
                         variant="filled"
                         hiddenLabel
                         multiline
                         placeholder="Make the script funnier. And add a sense of urgency to encourage the audience to buy the product now."
                         value={inputs.currentFollowupInput}
                         onChange={handleChange("currentFollowupInput")}
                         onKeyDown={handleKeyDown}
                         autoFocus
                         InputProps={{
                           disableUnderline: true,
                         }}
                         sx={{
                           '& .MuiFilledInput-root': {
                             paddingX: "43px",
                             color: theme.palette.primary.black,
                             background: theme.palette.primary.white,
                             border: "3px solid black",
                             borderRadius: "18px",
                             fontWeight: 500,
                             fontSize: "20px",
                             lineHeight: "26px",
                             // Override the focus styles for the filled variant
                             '&.Mui-focused': {
                               background: theme.palette.primary.white,
                               backgroundColor: theme.palette.primary.white,
                             },
                           },
                           width: "550px",
                         }}
              />
              {/*This hidden input field is a hack to prevent the Enter key from submitting the form and reloading the page*/}
              <input hidden type="text"/>
            </Box>
          </Box>
          <Box display="flex" pt="46px" pb="100px">
            <Box margin="auto">
              <Button variant="contained"
                      onClick={handleSubmit}
                      color="secondary"
                      disabled={!inputs.currentFollowupInput}
                      sx={{
                        boxShadow: 10,
                        width: "175px",
                      }}>
                Run
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Container>
  )
}
