import React, {useState} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Box, IconButton, Modal, List, ListItem, ListItemText,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import {KEYS} from "../../libs/company-data";
import {UGC_COPY_TYPES, COPY_TYPES} from "../../libs/constants";

const BLACK_BORDER = "3px solid #36413A"

const copyDetails = {
  [COPY_TYPES.UGC]: {
    title: 'The Donut Classic',
    points: [
      { header: 'Hook', description: 'A compelling opening statement or question designed to grab the viewer\'s attention immediately.' },
      { header: 'Product Intro', description: 'Introducing the product as a potential solution to the problem or need.' },
      { header: 'Problem', description: 'A description of the issue or pain point the target audience is experiencing.' },
      { header: 'Features/USP', description: 'Describing the unique features or unique selling propositions (USPs) of the product.' },
      { header: 'Demo', description: 'Demonstrating how the product works, showing its features and functionality.' },
      { header: 'Benefit', description: 'Highlighting the key advantages and positive outcomes of using the product.' },
      { header: 'Desired End Result', description: 'Describing the ideal outcome the user can expect from using the product.' },
      { header: 'CTA (Call to Action)', description: 'A prompt encouraging the viewer to take a specific action, such as purchasing, signing up, or learning more.' },
    ],
  },
  [COPY_TYPES.UGC_PROBLEM_AGITATE_SOLUTION]: {
    title: 'Problem, Agitate, Solution',
    points: [
      { header: 'Hook', description: 'A problem-focused, compelling opening statement or question designed to grab the viewer\'s attention immediately.' },
      { header: 'Problem', description: 'A more thorough description of the issue or pain point the target audience is experiencing.' },
      { header: 'Agitate Problem', description: 'Amplifying the problem to create a sense of urgency or discomfort.' },
      { header: 'Product Intro', description: 'Introducing the product as a potential solution to the problem.' },
      { header: 'Product Demo', description: 'Demonstrating how the product works, showing its features and functionality.' },
      { header: 'Benefits', description: 'Highlighting the key advantages and positive outcomes of using the product.' },
      { header: 'Social Proof', description: 'Providing evidence of the product’s effectiveness through testimonials, reviews, or endorsements.' },
      { header: 'CTA (Call to Action)', description: 'A prompt encouraging the viewer to take a specific action, such as purchasing, signing up, or learning more.' },
    ],
  },
  [COPY_TYPES.UGC_COMPETITOR_BASHING]: {
    title: 'Competitor Bashing',
    points: [
      { header: 'Hook', description: 'A compelling opening statement or question designed to grab the viewer\'s attention immediately that mentions a competitors’ product.' },
      { header: 'Problem', description: 'A description of the issue or pain point the target audience is experiencing.' },
      { header: 'Agitate Problem', description: 'Amplifying the problem to create a sense of urgency or discomfort.' },
      { header: 'Product Intro', description: 'Introducing the product as a potential solution to the problem.' },
      { header: 'Feature/Benefit', description: 'Highlighting specific features and the benefits they provide.' },
      { header: 'Bad Alternative', description: 'Criticizing other solutions or products that don’t solve the problem effectively.' },
      { header: 'Results', description: 'Showing the positive outcomes of using your product.' },
      { header: 'CTA (Call to Action)', description: 'A prompt encouraging the viewer to take a specific action, such as purchasing, signing up, or learning more.' },
    ],
  },
  [COPY_TYPES.UGC_BENEFIT_FOCUSED]: {
    title: 'Benefit-Focused',
    points: [
      { header: 'Hook', description: 'A benefits-focused, compelling opening statement or question designed to grab the viewer\'s attention immediately.' },
      { header: 'Product Intro', description: 'Introducing the product as a potential solution to the problem.' },
      { header: 'Failed Solution', description: 'Describing other solutions that have failed to solve the problem effectively.' },
      { header: 'Benefit', description: 'Highlighting the key advantages and positive outcomes of using the product.' },
      { header: 'Features/USP', description: 'Describing the unique features or unique selling propositions (USPs) of the product.' },
      { header: 'Benefit', description: 'Reinforcing the key advantages and positive outcomes of using the product.' },
      { header: 'Demo', description: 'Demonstrating how the product works, showing its features and functionality.' },
      { header: 'Summary of Solution', description: 'Summarizing how the product solves the problem.' },
      { header: 'CTA (Call to Action)', description: 'A prompt encouraging the viewer to take a specific action, such as purchasing, signing up, or learning more.' },
    ],
  },
  [COPY_TYPES.UGC_PRODUCT_DIFFERENTIATION]: {
    title: 'Product Differentiation',
    points: [
      { header: 'Hook', description: 'A compelling opening statement or question that sets the product apart, designed to grab the viewer\'s attention immediately.' },
      { header: 'Problem', description: 'A description of the issue or pain point the target audience is experiencing.' },
      { header: 'Failed Solution', description: 'Describing other solutions that have failed to solve the problem effectively.' },
      { header: 'Product Intro', description: 'Introducing the product as a potential solution to the problem.' },
      { header: 'Features/USP', description: 'Describing the unique features or unique selling propositions (USPs) of the product.' },
      { header: 'Demo', description: 'Demonstrating how the product works, showing its features and functionality.' },
      { header: 'Benefit', description: 'Highlighting the key advantages and positive outcomes of using the product.' },
      { header: 'Desired End Result', description: 'Describing the ideal outcome the user can expect from using the product.' },
      { header: 'CTA (Call to Action)', description: 'A prompt encouraging the viewer to take a specific action, such as purchasing, signing up, or learning more.' },
    ],
  },
  [COPY_TYPES.UGC_SURPRISE_ME]: {
    title: 'Surprise Me!',
    points: [
      { header: '', description: 'Feeling adventurous? Let Mr. Oven choose a random template for you!' },
    ],
  },
};

function CopyTypeSelector(copyType, handleChange) {
  const [expanded, setExpanded] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', points: [] });

  const options = [
    COPY_TYPES.UGC,
    COPY_TYPES.UGC_PROBLEM_AGITATE_SOLUTION,
    COPY_TYPES.UGC_COMPETITOR_BASHING,
    COPY_TYPES.UGC_BENEFIT_FOCUSED,
    COPY_TYPES.UGC_PRODUCT_DIFFERENTIATION,
    COPY_TYPES.UGC_SURPRISE_ME,
  ]

  const handleSelect = (event) => {
    handleChange(KEYS.COPY_TYPE)({
      target: {
        value: event,
      }
    })
  }

  const handleInfoClick = (type) => {
    setModalContent(copyDetails[type]);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setModalContent({ title: '', points: [] });
  };

  return (
    <Box sx={{width: '490px'}}>
      <Accordion expanded={expanded}
                 sx={{
                   borderRadius: "12px",
                   boxShadow: 0,
                 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          sx={{
            border: BLACK_BORDER,
            borderRadius: "12px",
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '69px',
            '&.Mui-expanded': {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Typography fontSize={24} sx={{flexGrow: 1}}>UGC Script</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{
          padding: 0,
        }}>
          <Box flexDirection="column">
            {options.map((option, idx) => (
              <Box key={idx}
                   sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                <Button
                  key={option}
                  variant="contained"
                  color={copyType === option ? 'secondary' : 'primary'}
                  onClick={() => handleSelect(option)}
                  sx={{
                    boxShadow: 0,
                    margin: 0,
                    width: '100%',
                    height: '50px',
                    borderTop: idx === 0 ? 0 : '0.5px solid #36413A',
                    borderLeft: BLACK_BORDER,
                    borderRight: BLACK_BORDER,
                    borderBottom: idx === options.length - 1 ? BLACK_BORDER : '0.5px solid #36413A',
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: idx === options.length - 1 ? '12px' : 0,
                    borderBottomRightRadius: idx === options.length - 1 ? '12px' : 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontSize={24}>{UGC_COPY_TYPES[option]}</Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleInfoClick(option);
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                </Button>
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
      <div>
        <Button variant="contained"
                color={copyType === COPY_TYPES.STATIC_HEADLINE ? "secondary" : "primary"}
                onClick={() => handleSelect(COPY_TYPES.STATIC_HEADLINE)}
                sx={{
                  borderRadius: "12px",
                  boxShadow: 0,
                  justifyContent: "flex-start",
                  marginTop: "12px",
                  width: "490px",
                }}>
          Static Headline
        </Button>
      </div>
      <div>
        <Button variant="contained"
                color={copyType === COPY_TYPES.LANDING_PAGE_ADVERTORIAL ? "secondary" : "primary"}
                onClick={() => handleSelect(COPY_TYPES.LANDING_PAGE_ADVERTORIAL)}
                sx={{
                  borderRadius: "12px",
                  boxShadow: 0,
                  justifyContent: "flex-start",
                  marginTop: "10px",
                  width: "490px",
                }}
        >
          Advertorial LP
        </Button>
      </div>

      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 900,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
            padding: "64px 50px 64px 50px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
            }}
          >
            <CloseIcon sx={{ fontSize: 45 }} />
          </IconButton>
          <Typography
            variant="h3"
            component="h3"
            fontSize={46}
            textAlign="center"
            sx={{ fontFamily: 'Tangerine', mb: 2 }}
          >
            {modalContent.title}
          </Typography>
          <List>
            {modalContent.points.map((point, idx) => (
              <ListItem key={idx} sx={{ alignItems: 'flex-start', paddingBottom: 0.2, paddingTop: 0.2 }}>
                <ListItemText
                  primary={
                    <Typography
                      sx={{ fontWeight: 'bold', display: 'inline', fontSize: 24 }}
                    >
                      {point.header.length > 0 ? `• ${point.header}:` : ''}
                    </Typography>
                  }
                  secondary={
                    <Typography sx={{ display: 'inline', fontSize: 24 }}>
                      {` ${point.description}`}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>

    </Box>
  );
}

export default CopyTypeSelector;
