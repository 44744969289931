import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import {Amplify} from 'aws-amplify';
import {useTheme} from '@mui/material/styles'
import {Button, Typography} from "@mui/material";
import {Microwave} from "@mui/icons-material";

import {getAwsConfig} from "../../libs/utils";
import ampConfig from '../../aws-exports';
import slsConfig from '../../serverless-exports';


const awsConfig = getAwsConfig(ampConfig, slsConfig);
Amplify.configure(awsConfig);

function NavButton(IconComponent, {text, onClick, href}) {
  return (
    <Button variant="text"
            onClick={onClick}
            href={href}>
      <IconComponent sx={{marginRight: '7px', color: 'white'}}/>
      <Typography variant="h4" fontSize={19} fontWeight={500} color="white">
        {text}
      </Typography>
    </Button>
  )
}

function Navbar() {
  const theme = useTheme()

  const logout = () => {
    const data = {
      UserPoolId: awsConfig.aws_user_pools_id,
      ClientId: awsConfig.aws_user_pools_web_client_id,
    };
    const userPool = new AmazonCognitoIdentity.CognitoUserPool(data);
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          alert(err);
          return;
        }
        console.log('session validity: ' + session.isValid());
        cognitoUser.signOut();
        console.log("Signed-out");
        window.location.reload();
      });
    } else {
      console.log("Already signed-out")
    }
  };

  return (
    <AppBar position="static" sx={{
      backgroundColor: theme.palette.primary.black,
      boxShadow: 0,
      height: '70px',
    }}>
      <Container maxWidth="xl" sx={{pt: '6px'}}>
        <Toolbar disableGutters>
          <Box sx={{flexGrow: 1}}>
            <a href={process.env.REACT_APP_HOME_URL}>
              <img src="/prizm-logo-white.svg" alt="logo svg" width="100"/>
            </a>
          </Box>
          <Box sx={{flexGrow: 0, paddingBottom: '8px', marginRight: '18px'}}>
          </Box>
          <Box sx={{flexGrow: 0, paddingBottom: '8px', marginRight: '18px'}}>
            {NavButton(Microwave, {
              text: "Mr. Oven",
              href: '/creative',
            })}
          </Box>
          <Box sx={{flexGrow: 0, paddingBottom: '8px'}}>
            {NavButton(LogoutIcon, {
              text: "Logout",
              onClick: logout,
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar