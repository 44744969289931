import React from "react";
import {Box, Button, Container, Paper, Slide, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import WindowTopElements from "../base/window-top-elements";
import InputColumn from "../base/input-column";
import {CONTAINER_SX} from "../../libs/constants";

export default function ValuePropsForm({nextStep, prevStep, handleChange, inputs, direction}) {
  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (!!inputs.valueProps && event.key === 'Enter') {
      nextStep()
    }
  }

  return (
    <Container id="-fields-form-container"
               sx={CONTAINER_SX}>
      <Box sx={{opacity: 0}}>
        <input onKeyDown={handleKeyDown} autoFocus/>
      </Box>
      <Box pt="40px" pl="24px">
        <Typography variant="h2" component="h1" fontSize={36} fontWeight={500} margin="auto">
          What are the value props?
        </Typography>
      </Box>
      <Slide direction={direction ? "right" : "left"}
             timeout={400}
             in={true}>
        <Box>
          <Paper elevation={3}
                 sx={{
                   marginTop: "48px",
                   border: "2px solid black",
                   borderRadius: "16px"
                 }}>
            <WindowTopElements/>
            <Container maxWidth="lg">
              <Box pt="24px" pl="24px" pb="20px"/>
              <InputColumn inputs={inputs}
                           inputKey="valueProps"
                           handleChange={handleChange}
                           placeholder='"Moisture-wicking and breathable, so you don’t get sweaty"'
                           objectName="value props"
                           autoFocus
              />
              <Box pt="24px"/>
            </Container>
          </Paper>
          <Box display="flex" pt="46px" pb="100px">
            <Box margin="auto">
              <Button variant="outlined"
                      onClick={prevStep}
                      color="primary"
                      sx={{
                        boxShadow: 10,
                        marginRight: "25px",
                        color: theme.palette.primary.black
                      }}>
                Back
              </Button>
              <Button variant="contained"
                      onClick={nextStep}
                      disabled={inputs.valueProps.length <= 0}
                      color="secondary"
                      sx={{
                        boxShadow: 10,
                        width: "175px",
                      }}>
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Container>
  )
}
