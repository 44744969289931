import {Box, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import React from "react";
import {
  LABELED_FIELD_DEFAULT_VALUES,
  LABELED_FIELD_DESCRIPTIONS,
  LABELED_FIELD_TYPES,
  LOW_ROI_FILTER_COLUMNS
} from "../../libs/constants";
import InputTags from "./input-tags";
import MultiTags from "./multi-tags";

export default function LabeledField(args) {
  const {field, inputKey} = args
  const theme = useTheme()

  return (
    <>
      <Grid item xs={4}>
        <Box p="24px">
          <Typography variant="subtitle2">
            {field}
          </Typography>
          <Typography variant="body" pt="8px">
            {LABELED_FIELD_DESCRIPTIONS[inputKey]}
          </Typography>
          {LABELED_FIELD_DEFAULT_VALUES[inputKey] && (
            <Box>
              <Typography variant="subtitle1" pt="8px" color={theme.palette.primary.gray}>
                {`Default: ${LABELED_FIELD_DEFAULT_VALUES[inputKey]}`}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box p="24px">
          {getField(args)}
        </Box>
      </Grid>
    </>
  )
}

function Toggle({inputs, handleChange}) {
  const theme = useTheme()

  const handleToggle = (event, newAlignment) => {
    handleChange("lowRoiFilterColumn")({
      target: {
        value: newAlignment
      }
    })
  }

  return (
    <Box mr="24px">
      <Typography variant="body" color={theme.palette.primary.gray}>
        KPI
      </Typography>
      <Box mt="8px">
        <ToggleButtonGroup
          value={inputs.lowRoiFilterColumn}
          exclusive
          onChange={handleToggle}
        >
          <ToggleButton value={LOW_ROI_FILTER_COLUMNS.ROAS}>ROAS</ToggleButton>
          <ToggleButton value={LOW_ROI_FILTER_COLUMNS.CPA}>CPA</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  )
}

const LowROIFilterField = ({inputs, handleChange}) => {
  const isCPA = inputs.lowRoiFilterColumn === LOW_ROI_FILTER_COLUMNS.CPA

  return (
    <Box display="flex">
      <Box sx={{flexGrow: 0}}>
        <Toggle inputs={inputs} handleChange={handleChange}/>
      </Box>
      <Box sx={{flexGrow: 1}}>
        <NumberField inputs={inputs}
                     inputKey="lowRoiFilterThresh"
                     handleChange={handleChange}
                     placeholder={isCPA ? 'e.g. "1000"' : 'e.g. "5"'}
                     label={isCPA ? "Minimum Media Spend" : "Minimum ROAS"}
                     header="KPI Threshold"
                     isDecimal/>
      </Box>
    </Box>
  )
}
export const NumberField = ({field, inputs, inputKey, handleChange, header, label, placeholder, isPercent, isDecimal}) => {
  const value = inputs[inputKey]
  const theme = useTheme()

  const handleTextUpdate = e => {
    const regex = isDecimal ? /^[0-9.\b]+$/ : /^[0-9\b]+$/
    if (e.target.value === "" || regex.test(e.target.value)) {
      handleChange(inputKey)(e)
    }
  };

  return (
    <Box>
      <Typography variant="body" color={theme.palette.primary.gray}>
        {header || `${isPercent ? "Percent" : "Amount"} (${isDecimal ? "Decimal" : "Integer"})`}
      </Typography>
      <Box pt="4px">
        <TextField id={`${inputKey}-textfield`}
                   value={value}
                   onChange={handleTextUpdate}
                   label={label || field}
                   placeholder={placeholder || 'e.g. "5"'}
                   variant="outlined"
                   fullWidth/>
      </Box>
    </Box>
  )
}


const getField = ({field, type, inputs, inputKey, handleChange, placeholder, header, objectName}) => {
  switch (type) {
    case LABELED_FIELD_TYPES.TAGS:
      return (
        <InputTags inputs={inputs} inputKey={inputKey} handleChange={handleChange} placeholder={placeholder} objectName={objectName}/>
      )
    case LABELED_FIELD_TYPES.NUMBER_AMOUNT_INT:
      return (
        <NumberField inputs={inputs} inputKey={inputKey} handleChange={handleChange} placeholder={placeholder} label={field} header={header}/>
      )
    case LABELED_FIELD_TYPES.NUMBER_AMOUNT_DECIMAL:
      return (
        <NumberField inputs={inputs} inputKey={inputKey} handleChange={handleChange} placeholder={placeholder} label={field} header={header} isDecimal/>
      )
    case LABELED_FIELD_TYPES.NUMBER_PERCENT:
      return (
        <NumberField inputs={inputs} inputKey={inputKey} handleChange={handleChange} placeholder={placeholder} label={field} header={header} isPercent isDecimal/>
      )
    case LABELED_FIELD_TYPES.TAG_MAP:
      return (
        <MultiTags inputs={inputs} inputKey={inputKey} handleChange={handleChange}/>
      )
    case LABELED_FIELD_TYPES.LOW_ROI_FILTER:
      return (
        <LowROIFilterField inputs={inputs} handleChange={handleChange}/>
      )
    default:
    //
  }
}
