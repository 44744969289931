import React from "react"

import TargetAudienceForm from "./target-audience-form";
import ValuePropsForm from "./value-props-form";
import RunCreativeScript from "./run-creative-script";
import DurationForm from "./duration-form";
import ObjectiveForm from "./objective-form";
import ProductDefinitionForm from "./product-definition-form";
import OptionalFieldsCreativeForm from "./optional-fields-creative-form";
import FollowupRequestForm from "./followup-request-form";
import HomeCreative from "../creative/home-creative";
import {KEYS} from "../../libs/company-data";
import {COPY_TYPES, POV_TYPES, LANDING_PAGE_ADVERTORIAL_TYPES} from "../../libs/constants";

export const INPUTS_ZERO_STATE = {
  // ---- required ----
  [KEYS.COMPANY_NAME]: "",
  [KEYS.PRODUCT_DEFINITION]: "",
  [KEYS.VALUE_PROPS]: [],
  [KEYS.TARGET_AUDIENCE]: "",
  [KEYS.OBJECTIVE]: "",
  //  ---- optional: UGC ----
  [KEYS.DURATION_SECONDS]: "",
  [KEYS.TONE]: [],
  //  ---- optional: landing page ----
  [KEYS.ARTICLE_TYPE]: LANDING_PAGE_ADVERTORIAL_TYPES.GENERAL,
  [KEYS.POV]: POV_TYPES.THIRD,
  //  ---- optional: all ----
  [KEYS.FOLLOWUP_REQUESTS]: [],
  // ---- hidden fields ----
  [KEYS.CURRENT_FOLLOWUP_INPUT]: "", // Used to track the last followup input and appended to the followupInputs array; not sent to the backend
  [KEYS.COMPLETION_RESPONSE]: "", // Used to track the generated output and appended to the followupInputs array; not sent to the backend
  [KEYS.COPY_TYPE]: COPY_TYPES.UGC,
}

export default class CreativeParentForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      direction: true,
      inputs: INPUTS_ZERO_STATE,
    }
  }

  // proceed to the next step
  nextStep = () => {
    let { step, inputs } = this.state;
    let increment = 1;

    if (step === 5) {
      // Skip duration and optional form steps for static headline copy
      if (inputs[KEYS.COPY_TYPE] === COPY_TYPES.STATIC_HEADLINE) {
        increment = 3;
      }
      // SKip duration for landing page copy
      if (inputs[KEYS.COPY_TYPE] === COPY_TYPES.LANDING_PAGE_ADVERTORIAL) {
        increment = 2;
      }
    }

    this.setState({
      step: step + increment,
      direction: true,
    });
  }

  // go back to previous step
  prevStep = () => {
    let { step, inputs } = this.state;
    let decrement = 1;

    // Skip duration and optional form steps for static headline copy
    if (inputs[KEYS.COPY_TYPE] === COPY_TYPES.STATIC_HEADLINE && step === 8) {
      decrement = 3;
    }

    // Skip duration for landing page copy
    if (inputs[KEYS.COPY_TYPE] === COPY_TYPES.LANDING_PAGE_ADVERTORIAL && step === 7) {
      decrement = 2;
    }

    this.setState({
      step: step - decrement,
      direction: false,
    });
  }

  // restart from first step
  restart = () => {
    this.setState({
      step: 1,
      direction: true,
      inputs: INPUTS_ZERO_STATE,
    })
  }

  // jump directly to the run step
  goToRunStep = () => {
    this.setState({
      step: 8,
      direction: true,
    })
  }

  // handle field change
  handleChange = input => e => {
    if (e.target.value === '\n') {
      return;
    }

    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [input]: e.target.value
      }
    }), () => {
      if (e.callback) {
        e.callback();
      }
    });
  }

  // load default input set into inputs state
  loadInputs = inputDefaults => {
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        ...inputDefaults,
      }
    }))
  }

  render() {
    const {step, inputs} = this.state;
    const {theme} = this.props;

    switch (step) {
      case 1:
        return (
          <HomeCreative
            nextStep={this.nextStep}
            goToRunStep={this.goToRunStep}
            handleChange={this.handleChange}
            loadInputs={this.loadInputs}
            inputs={inputs}/>
        )
      case 2:
        return (
          <ProductDefinitionForm nextStep={this.nextStep}
                                 prevStep={this.prevStep}
                                 handleChange={this.handleChange}
                                 inputs={inputs}
                                 direction={this.state.direction}
          />
        )
      case 3:
        return (
          <ValuePropsForm nextStep={this.nextStep}
                          prevStep={this.prevStep}
                          handleChange={this.handleChange}
                          inputs={inputs}
                          direction={this.state.direction}
          />
        )
      case 4:
        return (
          <TargetAudienceForm nextStep={this.nextStep}
                              prevStep={this.prevStep}
                              handleChange={this.handleChange}
                              inputs={inputs}
                              direction={this.state.direction}
          />
        )
      case 5:
        return (
          <ObjectiveForm nextStep={this.nextStep}
                         prevStep={this.prevStep}
                         handleChange={this.handleChange}
                         inputs={inputs}
                         direction={this.state.direction}
          />
        )
      case 6:
        return (
          <DurationForm nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        inputs={inputs}
                        direction={this.state.direction}
          />
        )
      case 7:
        return (
          <OptionalFieldsCreativeForm nextStep={this.nextStep}
                                      prevStep={this.prevStep}
                                      handleChange={this.handleChange}
                                      inputs={inputs}
                                      direction={this.state.direction}
          />
        )
      case 8:
        return (
          <RunCreativeScript prevStep={this.prevStep}
                             nextStep={this.nextStep}
                             restart={this.restart}
                             handleChange={this.handleChange}
                             inputs={inputs}
                             theme={theme}
                             direction={this.state.direction}
          />
        )
      case 9:
        return (
          // You can only go back from FollowupInputForm to rerun, so we do not pass in nextStep
          <FollowupRequestForm prevStep={this.prevStep}
                               handleChange={this.handleChange}
                               inputs={inputs}
                               direction={this.state.direction}
          />
        )
      default:
      //
    }
  }
}
