import React from "react";
import {Alert, Box, Button, Container, Slide, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import DragDropFile from "../base/drag-drop-file";
import {CONTAINER_SX, TOAST_SEVERITY_STATUSES} from "../../libs/constants";
import Loading from "../base/loading";

export default function SearchTermReportUpload({nextStep, prevStep, handleChange, inputs, createToast, direction}) {
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      nextStep()
    }
  }

  return (
      <Container id="search-term-report-upload-container"
                 maxWidth="sm"
                 sx={CONTAINER_SX}>
        <Box sx={{opacity: 0}}>
          <input onKeyDown={handleKeyDown} autoFocus/>
        </Box>
        {loading ?
          <Loading imgFilename="/sparkles-loading.gif"
                   pixelWidth="200px"
                   text="Uploading Search Term Report..."
          /> :
          <Slide direction={direction ? "right" : "left"}
                 timeout={400}
                 in={true}>
            <Box>
              <Box display="flex" pt="51px">
                <Typography variant="h4"
                            component="h1"
                            fontSize={36}
                            fontWeight={500}
                            margin="auto">
                  Upload Search Term Report
                </Typography>
              </Box>
              {!!inputs.inputSourceFilename &&
                <Box display="flex" pt="18px">
                  <Alert severity={TOAST_SEVERITY_STATUSES.SUCCESS} sx={{margin: "auto"}}>
                    {`${inputs.inputSourceFilename} selected`}
                  </Alert>
                </Box>}
              <Box pt="36px"/>
              <DragDropFile nextStep={nextStep}
                            createToast={createToast}
                            setLoading={setLoading}
                            inputKey="inputSourceFilename"
                            handleChange={handleChange}/>
              <Box display="flex" pt="46px">
                <Box margin="auto">
                  <Button variant="outlined"
                          onClick={prevStep}
                          color="primary"
                          sx={{
                            boxShadow: 10,
                            marginRight: "25px",
                            color: theme.palette.primary.black
                          }}>
                    Back
                  </Button>
                  <Button variant="contained"
                          onClick={nextStep}
                          color="secondary"
                          disabled={!inputs.inputSourceFilename}
                          sx={{
                            boxShadow: 10,
                            width: "175px",
                          }}>
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </Slide>
        }
      </Container>
  )
}
