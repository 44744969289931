import React from 'react';
import ReactDOM from 'react-dom';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import {Route, Routes, BrowserRouter} from 'react-router-dom'

import DigitalApp from './components/digital/digital-app';
import CreativeApp from './components/creative/creative-app';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  palette: {
    primary: {
      main: "#F6EDE8",
      black: "#36413A",
      white: "#FFFAF8",
      gray: "#727272",
      green: "#3A8165",
      pink: "#EDD5F0",
    },
    secondary: {
      main: "#CCE0D9"
    },
    tertiary: {
      main: "#36413A",
    },
    action: {
      disabledBackground: "#9F9F9F",
      disabled: "#000000"
    },
    info: {
      main: "#3A8165",
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Tangerine';
          src: url('/fonts/Tangerine.otf') format('opentype');
          font-weight: normal;
          font-style: normal;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          height: "63px",
          border: "3px solid black",
          boxShadow: 10,
          borderRadius: "24px",
          fontSize: "24px",
          fontWeight: 400,
          paddingLeft: "30px",
          paddingRight: "30px",
        },
        outlined: {
          height: "63px",
          border: "3px solid black",
          boxShadow: 10,
          borderRadius: "24px",
          fontSize: "24px",
          fontWeight: 400,
          paddingLeft: "30px",
          paddingRight: "30px",
          "&:hover": {
            backgroundColor: "#36413A",
            color: "#FFFAF8",
            border: "3px solid #36413A",
          },
        }
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6EDE8",
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: "#36413A",
          color: "#FFFFFF",
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: '#E3EEFA !important',
          },
        }
      }
    },
  },
  typography: {
    fontFamily: [
      "Outfit",
      "Tangerine",
    ].join(','),
    subtitle2: {
      fontSize: "16px",
      fontWeight: 600,
    },
    body: {
      fontSize: "14px",
      fontWeight: 400,
    },
    button: {
      textTransform: "none"
    },
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<DigitalApp/>}/>
          <Route path="/creative" element={<CreativeApp/>}/>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
