export const S3_ACCESS_LEVEL = 'public';

export const S3_PREFIX = {
  INPUTS: 'inputs/',
  OUTPUTS: 'outputs/',
};

export const RESPONSE_STATUS = {
  BAD_REQUEST: 'Bad Request',
  ERROR: 'Error',
  SUCCESS: 'Success',
};

export const TOAST_SEVERITY_STATUSES = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};

export const LABELED_FIELD_DESCRIPTIONS = {
  // Digital fields
  companyNameVariations: "There are always multiple ways one could search for a brand. For example, \"Thrive\" vs \"Thrive Market\". Insert all the alternatives you can think of for the brand's name.",
  productNames: "Sometimes, customers will search a brand by its well-known products -- in which case this query should be categorized under brand (and not non-brand). List any product listings that customers may search for.",
  negatives: "Sometimes, there are keywords that we DON'T want to bid on. For example, functionalities or products that a brand does not offer.",
  prioritizedTokensInSlug: "Prioritized tokens occur first in ad group slugs and do not affect how keywords are mapped to ad groups",
  explicitCampaignTokens: "This is your desired keyword hierarchy, which determines how keywords are prioritized within your campaign structure and naming convention.",
  nonTokensForKwe: "List of words that aren't treated as tokens in ad group report",
  equivalentTokens: "Sometimes, keywords should be consolidated -- as they belong in the same ad group. The first keyword specified in each row takes priority over the other keywords in the row.",
  lowPerformingKeywords: "This feature will help you filter out poor performing keywords by client KPI.",
  maxTokenTierDepth: "This function lets you dictate how granular you want your ad group naming to be/ how many tokens to segment per ad group.",
  conversionThresh: "This functions allows you to filter for keywords that have contributed to minimum number of conversions.",
  costPercentileThresh: "This functions allows you to filter for keywords that have contributed to minimum amount of media spend.",
  imprPercentileThresh: "This functions allows you to filter for keywords that have contributed to minimum number of impressions.",
  wordCountThresh: "This functions allows you to filter for keywords under a certain number of words/filter out long tail keywords.",
  tokenCharLenThresh: "Filter for tokens that contain more than X characters. Default value is 3.",
  // Creative fields
  tone: "List of adjectives that describes the infuencer's desired tone of voice",
}

// replicated from the backend; these are just used to inform the user on the frontend
export const LABELED_FIELD_DEFAULT_VALUES = {
  maxTokenTierDepth: "3",
  conversionThresh: "> 0",
  // represented as 0.25 on the backend but 25 on the frontend (FE multiplies by 100)
  costPercentileThresh: "> 25",
  // represented as 0.60 on the backend but 60 on the frontend (FE multiplies by 100)
  imprPercentileThresh: "> 60",
  wordCountThresh: "<= 10",
  tokenCharLenThresh: ">= 3",
}

export const LABELED_FIELD_TYPES = {
  NUMBER_AMOUNT_INT: "NUMBER_AMOUNT_INT",
  NUMBER_AMOUNT_DECIMAL: "NUMBER_AMOUNT_DECIMAL",
  NUMBER_PERCENT: "NUMBER_PERCENT",
  TAGS: "TAGS",
  TAG_MAP: "TAG_MAP",
  LOW_ROI_FILTER: "LOW_ROI_FILTER",
}

export const LOW_ROI_FILTER_COLUMNS = {
  CPA: "CPA",
  ROAS: "ROAS",
}

export const COPY_TYPES = {
  LANDING_PAGE_ADVERTORIAL: 'LANDING_PAGE_ADVERTORIAL',
  STATIC_HEADLINE: 'STATIC_HEADLINE',
  UGC: 'UGC', // AKA "The Donut Classic",
  UGC_PROBLEM_AGITATE_SOLUTION: 'UGC_PROBLEM_AGITATE_SOLUTION',
  UGC_COMPETITOR_BASHING: 'UGC_COMPETITOR_BASHING',
  UGC_BENEFIT_FOCUSED: 'UGC_BENEFIT_FOCUSED',
  UGC_PRODUCT_DIFFERENTIATION: 'UGC_PRODUCT_DIFFERENTIATION',
  UGC_SURPRISE_ME: 'UGC_SURPRISE_ME',
}

export const UGC_COPY_TYPES = {
  [COPY_TYPES.UGC]: 'The Donut Classic',
  [COPY_TYPES.UGC_PROBLEM_AGITATE_SOLUTION]: 'Problem, Agitate, Solution',
  [COPY_TYPES.UGC_COMPETITOR_BASHING]: 'Competitor Bashing',
  [COPY_TYPES.UGC_BENEFIT_FOCUSED]: 'Benefit-Focused',
  [COPY_TYPES.UGC_PRODUCT_DIFFERENTIATION]: 'Product Differentiation',
  [COPY_TYPES.UGC_SURPRISE_ME]: 'Surprise Me!',
}

export const isCopyTypeUGC = (copyType) => (copyType in UGC_COPY_TYPES)

export const POV_TYPES = {
  FIRST: 'FIRST',
  THIRD: 'THIRD',
}

export const LANDING_PAGE_ADVERTORIAL_TYPES = {
  GENERAL: 'GENERAL',
  LISTICLE: 'LISTICLE',
}

export const CONTAINER_SX = {minHeight: "calc(100vh - 70px)"}