// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by serverless-amplify-plugin/0.0.1 on 2024-06-24T02:13:36.187Z

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_AN68IvR5t",
    "aws_user_pools_web_client_id": "40f2oim0m8ojbo5akj119igqib",
    "aws_cognito_identity_pool_id": "us-west-2:33557a04-113d-4f62-b12e-6970d61a0270",
};

export default awsmobile;
