import React from "react"

import RunSearchTermReport from "./run-search-term-report";
import HomeDigital from "../digital/home-digital";
import CompanyNameForm from "../digital/company-name-form";
import SearchTermReportUpload from "./search-term-report-upload";
import AdGroupReportUpload from "./ad-group-report-upload";
import OptionalFieldsDigitalForm from "./optional-fields-digital-form";
import {LOW_ROI_FILTER_COLUMNS} from "../../libs/constants";

const INPUTS_ZERO_STATE = {
  // ---- required ----
  companyName: "",
  inputSourceFilename: "",
  //  ---- optional ----
  adGroupReportFilename: "",
  // Brand Name Variations
  companyNameVariations: [],
  // Hero Product Name Variations
  productNames: [],
  // Negatives
  negatives: [],
  // Prioritized Tokens in Slug
  prioritizedTokensInSlug: [],
  // Explicit Campaign Tokens
  explicitCampaignTokens: [],
  // Equivalent Tokens
  equivalentTokens: [],
  // Low ROI Filter Column
  lowRoiFilterColumn: LOW_ROI_FILTER_COLUMNS.ROAS,
  // Low ROI Filter Threshold
  lowRoiFilterThresh: "",
  // Non-Tokens for Keyword Expansion
  nonTokensForKwe: [],
  // Max Sub-Token Depth
  maxTokenTierDepth: "",
  // Keyword Conversion Minimum
  conversionThresh: "",
  // Keyword Cost Percentile Minimum
  costPercentileThresh: "",
  // Keyword Impression Percentile Minimum
  imprPercentileThresh: "",
  // Keyword Word Count Minimum
  wordCountThresh: "",
  // Token Character Length Minimum
  tokenCharLenThresh: "",
}

export default class DigitalParentForm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      step: 1,
      direction: true,
      inputs: INPUTS_ZERO_STATE,
    }
  }

  // proceed to the next step
  nextStep = () => {
    const {step} = this.state;
    this.setState({
      step: step + 1,
      direction: true,
    })
  }

  // go back to previous step
  prevStep = () => {
    const {step} = this.state;
    this.setState({
      step: step - 1,
      direction: false,
    })
  }

  // restart from first step
  restart = () => {
    this.setState({
      step: 1,
      direction: true,
      inputs: INPUTS_ZERO_STATE,
    })
  }

  // handle field change
  handleChange = input => e => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [input]: e.target.value
      }
    });
  }

  render() {
    const {step} = this.state;
    const {inputs} = this.state;

    switch (step) {
      case 1:
        return (
          <HomeDigital
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            inputs={inputs}/>
        )
      case 2:
        return (
          <CompanyNameForm nextStep={this.nextStep}
                           prevStep={this.prevStep}
                           handleChange={this.handleChange}
                           inputs={inputs}
                           direction={this.state.direction}
          />
        )
      case 3:
        return (
          <SearchTermReportUpload nextStep={this.nextStep}
                                  prevStep={this.prevStep}
                                  handleChange={this.handleChange}
                                  createToast={this.props.createToast}
                                  inputs={inputs}
                                  direction={this.state.direction}
          />
        )
      case 4:
        return (
          <AdGroupReportUpload nextStep={this.nextStep}
                               prevStep={this.prevStep}
                               handleChange={this.handleChange}
                               createToast={this.props.createToast}
                               inputs={inputs}
                               direction={this.state.direction}
          />
        )
      case 5:
        return (
          <OptionalFieldsDigitalForm nextStep={this.nextStep}
                                     prevStep={this.prevStep}
                                     handleChange={this.handleChange}
                                     inputs={inputs}
                                     direction={this.state.direction}
          />
        )
      case 6:
        return (
          <RunSearchTermReport prevStep={this.prevStep}
                               restart={this.restart}
                               inputs={inputs}
                               direction={this.state.direction}
          />
        )
      default:
        //
    }
  }
}
