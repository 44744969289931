import {Box, Button, Container, Grid, Typography} from "@mui/material"
import {useTheme} from '@mui/material/styles'
import * as React from "react";

import FileTable from "../base/file-table";
import {S3_PREFIX} from "../../libs/constants";

export default function HomeDigital({nextStep, handleChange, inputs}) {

  const [inputFile, selectInputFile] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      nextStep()
    }
  }

  const startNewUpload = () => {
    handleChange("inputSourceFilename")({
      target: {
        value: ""
      }
    })
    nextStep()
  }

  const handleSelectInputFile = filename => {
    console.log('filename: ', filename)
    selectInputFile(filename)
    // Pass in a fake e.target.value to simulate the completion of a form field
    // This was done so that we can use a single function interface for updating the selected file and updating normal form fields
    handleChange("inputSourceFilename")({
      target: {
        value: filename
      }
    })
  }

  return (
    <Box id="home" onKeyDown={handleKeyDown} tabIndex="1" >
      <Box sx={{ position: "relative"}}>
        <Box sx={{
          display: "flex",
          width: "100%",
          position: "absolute",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          zIndex: 0
        }}>
          <img src="/purple-blob.svg" alt="purple blob"/>
        </Box>
        <Box sx={{
          bottom: 0,
          position: "absolute",
          maxHeight: "292px",
          zIndex: 0
        }}>
          <img src="/orange-blob.svg" alt="orange blob"/>
        </Box>
        <Container id="home-container-top"
                   maxWidth="false"
                   sx={{
                     backgroundColor: theme.palette.primary.main,
                     paddingTop: "110px",
                   }}>
          <Box display="flex">
              <Typography variant="h3" component="h1" fontSize={56} margin="auto" sx={{fontFamily: "Tangerine"}}>
                Welcome back!
              </Typography>
          </Box>
          <Box display="flex" pt="12px">
            <Box m="auto">
              <img src="/donut-wave.gif" alt="donut wave gif"/>
            </Box>
          </Box>
          <Box display="flex" pt="12px" pb="75px">
            <Button variant="contained"
                    onClick={startNewUpload}
                    color="secondary"
                    sx={{boxShadow: 10, width: "361px", margin: "auto"}}>
              Start a new upload
            </Button>
          </Box>
        </Container>
      </Box>
      <Container id="home-container-bottom"
                 maxWidth="false"
                 sx={{
                   backgroundColor: theme.palette.secondary.main,
                   minHeight: "calc(100vh - 70px - 633.5px)",
                 }}>
        <Box display="flex" pt="75px">
          <Typography variant="h4" component="h2" margin="auto">
            Run Prizm with existing files
          </Typography>
        </Box>
        <Container maxWidth="xl"
                   sx={{
                     backgroundColor: theme.palette.secondary.main,
                     // width: "100vw",
                   }}>
          <Grid container spacing={2} pt="50px">
            <Grid item xs={6}>
              <FileTable prefix={S3_PREFIX.INPUTS}
                         title="Search Term Reports"
                         subtitle="Select a report to get started"
                         rowsPerPage={rowsPerPage}
                         setRowsPerPage={setRowsPerPage}
                         handleFileSelect={handleSelectInputFile}/>
            </Grid>
            <Grid item xs={6}>
              <FileTable prefix={S3_PREFIX.OUTPUTS}
                         rowsPerPage={rowsPerPage}
                         setRowsPerPage={setRowsPerPage}
                         title="Generated Reports"/>
            </Grid>
          </Grid>
          <Box display="flex" pt="32px" pb="100px">
            <Button variant="contained"
                    onClick={nextStep}
                    color="primary"
                    disabled={!inputFile}
                    sx={{
                      boxShadow: 10,
                      width: "328px",
                      margin: "auto"
                    }}>
              Continue
            </Button>
          </Box>
        </Container>
      </Container>
    </Box>
  )
}
