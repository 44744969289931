import {Box} from "@mui/material";
import React from "react";

export const WindowTopElements = () => (
  <Box sx={{
    display: "flex",
    height: "56px",
    paddingTop: "19px",
    borderBottom: "2px solid black",
  }}>
    <Box sx={{paddingLeft: "22px"}}>
      <img src="/green-circle.svg" alt="green circle"/>
    </Box>
    <Box sx={{paddingLeft: "16px"}}>
      <img src="/pink-circle.svg" alt="pink circle"/>
    </Box>
    <Box sx={{paddingLeft: "16px"}}>
      <img src="/gray-circle.svg" alt="gray circle"/>
    </Box>
  </Box>
)

export default WindowTopElements