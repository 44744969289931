import React from "react";
import {Box, Button, Container, Slide, TextField, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import {CONTAINER_SX} from "../../libs/constants";

export default function CompanyNameForm({nextStep, prevStep, handleChange, inputs, direction, placeholder}) {
  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (!!inputs.companyName && event.key === 'Enter') {
      nextStep()
    }
  }

  return (
    <Container id="company-name-form-container"
               maxWidth="sm"
               sx={CONTAINER_SX}>
    >
      <Slide direction={direction ? "right" : "left"}
             timeout={400}
             in={true}>
        <Box>
          <Box display="flex" pt="51px">
            <Typography variant="h4" component="h1" fontSize={36} fontWeight={500} margin="auto">
              What company is this for?
            </Typography>
          </Box>
          <Box display="flex" pt="24px">
            <Box m="auto" component="form">
              <TextField id="company-textfield"
                         label="Company name"
                         variant="standard"
                         placeholder={placeholder}
                         value={inputs.companyName}
                         onChange={handleChange("companyName")}
                         onKeyDown={handleKeyDown}
                         autoFocus
                         InputProps={{
                           disableUnderline: true,
                         }}
                         sx={{
                           input: {
                             paddingX: "43px",
                             color: theme.palette.primary.black,
                             background: theme.palette.primary.white,
                             border: "3px solid black",
                             borderRadius: "18px",
                             fontWeight: 500,
                             fontSize: "24px",
                             lineHeight: "26px",
                             height: "65px",
                           },
                           width: "519px",
                         }}
              />
              {/*This hidden input field is a hack to prevent the Enter key from submitting the form and reloading the page*/}
              <input hidden type="text"/>
            </Box>
          </Box>
          <Box display="flex" pt="46px" pb="100px">
            <Box margin="auto">
              <Button variant="outlined"
                      onClick={prevStep}
                      color="primary"
                      sx={{
                        boxShadow: 10,
                        marginRight: "25px",
                        color: theme.palette.primary.black
                      }}>
                Back
              </Button>
              <Button variant="contained"
                      onClick={nextStep}
                      color="secondary"
                      disabled={!inputs.companyName}
                      sx={{
                        boxShadow: 10,
                        width: "175px",
                      }}>
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Container>
  )
}
