import React from "react";
import {Box, Button, Container, Slide, TextField, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import {CONTAINER_SX, COPY_TYPES} from "../../libs/constants";

export default function ObjectiveForm({nextStep, prevStep, handleChange, inputs, direction}) {
  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (!!inputs.objective && event.key === 'Enter') {
      nextStep()
    }
  }

  return (
    <Container id="objective-form-container"
               maxWidth="sm"
               sx={CONTAINER_SX}>
      <Slide direction={direction ? "right": "left"}
             timeout={400}
             in={true}>
        <Box>
          <Box display="flex" pt="51px">
            <Typography variant="h4" component="h1" fontSize={36} fontWeight={500} margin="auto">
              What is the objective of this creative?
            </Typography>
          </Box>
          <Box display="flex" pt="24px">
            <Box m="auto" component="form">
              <TextField id="objective-textfield"
                         variant="filled"
                         hiddenLabel
                         multiline
                         placeholder="Promoting underwear for the holidays"
                         value={inputs.objective}
                         onChange={handleChange("objective")}
                         onKeyDown={handleKeyDown}
                         autoFocus
                         InputProps={{
                           disableUnderline: true,
                         }}
                         sx={{
                           '& .MuiFilledInput-root': {
                             paddingX: "43px",
                             color: theme.palette.primary.black,
                             background: theme.palette.primary.white,
                             border: "3px solid black",
                             borderRadius: "18px",
                             fontWeight: 500,
                             fontSize: "20px",
                             lineHeight: "26px",
                             // Override the focus styles for the filled variant
                             '&.Mui-focused': {
                               background: theme.palette.primary.white,
                               backgroundColor: theme.palette.primary.white,
                             },
                           },
                           width: "550px",
                         }}
              />
              {/*This hidden input field is a hack to prevent the Enter key from submitting the form and reloading the page*/}
              <input hidden type="text"/>
            </Box>
          </Box>
          <Box display="flex" pt="46px" pb="100px">
            <Box margin="auto">
              <Button variant="outlined"
                      onClick={prevStep}
                      color="primary"
                      sx={{
                        boxShadow: 10,
                        marginRight: "25px",
                        color: theme.palette.primary.black
                      }}>
                Back
              </Button>
              <Button variant="contained"
                      onClick={nextStep}
                      color="secondary"
                      disabled={!inputs.objective}
                      sx={{
                        boxShadow: 10,
                        width: "175px",
                      }}>
                {inputs.copyType === COPY_TYPES.STATIC_HEADLINE ? "Run" : "Continue"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Container>
  )
}
