import React from "react";
import {Box, Button, Container, Grid, Paper, Slide, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import LabeledField from "../base/labeled-field";
import {CONTAINER_SX, LABELED_FIELD_TYPES} from "../../libs/constants";
import WindowTopElements from "../base/window-top-elements";

export default function OptionalFieldsDigitalForm({nextStep, prevStep, handleChange, inputs, direction}) {
  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      nextStep()
    }
  }

  return (
    <Container id="optional-fields-form-container"
               sx={CONTAINER_SX}>
      <Box sx={{opacity: 0}}>
        <input onKeyDown={handleKeyDown} autoFocus/>
      </Box>
      <Slide direction={direction ? "right" : "left"}
             timeout={400}
             in={true}>
        <Box>
          <Paper elevation={3}
                 sx={{
                   marginTop: "80px",
                   border: "2px solid black",
                   borderRadius: "16px"
                 }}>
            <WindowTopElements/>
            <Container maxWidth="lg">
              <Box pt="40px" pl="24px" pb="20px">
                <Typography variant="h2" component="h1" fontSize={36} fontWeight={500} margin="auto">
                  Optional Fields
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <LabeledField field="Brand Name Variations"
                              inputKey="companyNameVariations"
                              type={LABELED_FIELD_TYPES.TAGS}
                              placeholder='e.g. "Hu" "Hu chocolate"'
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Product Names"
                              inputKey="productNames"
                              type={LABELED_FIELD_TYPES.TAGS}
                              placeholder='e.g. "Always Pan" "Chocolate Hunks" "Hu Gems" "Sunbeam"'
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Negatives"
                              inputKey="negatives"
                              type={LABELED_FIELD_TYPES.TAGS}
                              placeholder='e.g. "dutch oven" "induction"'
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Prioritized Tokens in Ad Group Name"
                              inputKey="prioritizedTokensInSlug"
                              type={LABELED_FIELD_TYPES.TAGS}
                              placeholder='e.g. "baby" "evite" "invite”'
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Campaign Token Hierarchy"
                              inputKey="explicitCampaignTokens"
                              type={LABELED_FIELD_TYPES.TAGS}
                              placeholder='e.g. "lawn" "fertilizer" "grass" "weeds" "soil"'
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Non-Tokens for Keyword Expansion"
                              inputKey="nonTokensForKwe"
                              type={LABELED_FIELD_TYPES.TAGS}
                              placeholder='e.g. "diet" "aisle" "rise"'
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Equivalent Tokens"
                              inputKey="equivalentTokens"
                              type={LABELED_FIELD_TYPES.TAG_MAP}
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Low Performing Keywords"
                              inputKey="lowPerformingKeywords"
                              type={LABELED_FIELD_TYPES.LOW_ROI_FILTER}
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Max Sub-Token Depth"
                              inputKey="maxTokenTierDepth"
                              type={LABELED_FIELD_TYPES.NUMBER_AMOUNT_INT}
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Keyword Word Count Minimum"
                              inputKey="wordCountThresh"
                              type={LABELED_FIELD_TYPES.NUMBER_AMOUNT_INT}
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Token Character Length Minimum"
                              inputKey="tokenCharLenThresh"
                              type={LABELED_FIELD_TYPES.NUMBER_AMOUNT_INT}
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Keyword Conversion Minimum"
                              inputKey="conversionThresh"
                              type={LABELED_FIELD_TYPES.NUMBER_AMOUNT_DECIMAL}
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Keyword Cost Percentile Minimum"
                              inputKey="costPercentileThresh"
                              type={LABELED_FIELD_TYPES.NUMBER_PERCENT}
                              placeholder='e.g. "50.5"'
                              handleChange={handleChange}
                              inputs={inputs}/>
                <LabeledField field="Keyword Impression Percentile Minimum"
                              inputKey="imprPercentileThresh"
                              type={LABELED_FIELD_TYPES.NUMBER_PERCENT}
                              placeholder='e.g. "50.5"'
                              handleChange={handleChange}
                              inputs={inputs}
                              />
              </Grid>
              <Box pt="24px"/>
            </Container>
          </Paper>
          <Box display="flex" pt="46px" pb="100px">
            <Box margin="auto">
              <Button variant="outlined"
                      onClick={prevStep}
                      color="primary"
                      sx={{
                        boxShadow: 10,
                        marginRight: "25px",
                        color: theme.palette.primary.black
                      }}>
                Back
              </Button>
              <Button variant="contained"
                      onClick={nextStep}
                      color="secondary"
                      sx={{
                        boxShadow: 10,
                        width: "175px",
                      }}>
                Run
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Container>
  )
}
