import {Box, Grow, IconButton, TextField, Typography} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {useRef} from "react";

import {useTheme} from "@mui/material/styles";
import Tags from "./tags";


 function MultiInputTags({index, inputs, handleChange, inputKey, placeholder}) {
  const tags = inputs[inputKey][index]
  const tagRef = useRef();

  const handleDelete = value => {
    const currentInput =  inputs[inputKey]
    const row = currentInput[index]
    currentInput[index] = row.filter(val => val !== value)
    handleChange(inputKey)({
      target: {
        value: currentInput
      }
    })
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    // Don't allow the user to create empty tags by pressing "Enter" without first entering a string
    if (tagRef.current.value.length === 0) {
      return
    }
    const currentInput =  inputs[inputKey]
    currentInput[index].push(tagRef.current.value)
    handleChange(inputKey)({
      target: {
        value: currentInput
      }
    })

    tagRef.current.value = "";
  };

   return (
     <Grow in={true} timeout={400}>
       <Box sx={{flexGrow: 1}}>
         <form onSubmit={handleOnSubmit}>
           <TextField
             inputRef={tagRef}
             fullWidth
             variant='standard'
             size='small'
             sx={{margin: "1rem 0"}}
             margin='none'
             placeholder={tags.length < 3 ? `Enter tags (${placeholder})` : ""}
             InputProps={{
               startAdornment: (
                 <Box sx={{margin: "0 0.2rem 0 0", display: "flex"}}>
                   {tags.map((data, rowIndex) => <Tags data={data}
                                                       index={rowIndex}
                                                       key={rowIndex}
                                                       handleDelete={handleDelete}
                                                       highlightFirst/>)}
                 </Box>
               ),
             }}
           />
         </form>
       </Box>
     </Grow>
  );
}

export default function MultiTags({inputs, inputKey, handleChange}) {
  const theme = useTheme()
  const equivalentTokens = inputs["equivalentTokens"]

  const createRow = () => {
    const currentInput =  inputs[inputKey]
    currentInput.push([])
    handleChange(inputKey)({
      target: {
        value: currentInput
      }
    })
  }

  return (
    <Box id="multi-tags-input-box">
      {equivalentTokens.length === 0 ? (
        <Box onClick={createRow}
             sx={{
               cursor: "pointer",
               paddingY: "40px",
               border: "1px dashed #000000",
               borderRadius: "18px",
             }}
        >
          <Box display="flex">
            <AddCircle sx={{fontSize: "36px", margin: "auto", color: theme.palette.primary.black}}/>
          </Box>
          <Box display="flex" pt="8px">
            <Box margin="auto">
              <Typography variant="body" color={theme.palette.primary.gray}>
                Create a row to begin mapping
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{
          paddingY: "20px",
          border: "1px dashed #000000",
          borderRadius: "18px",
        }}
        >
          {equivalentTokens.map((row, rowIndex) => (
            <Box key={rowIndex} px="24px">
              <MultiInputTags index={rowIndex}
                              handleChange={handleChange}
                              inputs={inputs}
                              inputKey={inputKey}
                              placeholder={'e.g. "diet", "aisle", "rise"'}/>
            </Box>
          ))}
          <Box display="flex" pt="8px">
            <IconButton onClick={createRow} data-testid="create-row-button" sx={{margin: "auto"}}>
              <AddCircle sx={{fontSize: "36px", margin: "auto", color: theme.palette.primary.black}}/>
            </IconButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}
