import {Box, Button, Container, Slide, Typography} from "@mui/material"
import React from "react"
import Loading from "../base/loading"
import {API, Storage} from "aws-amplify"
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import {CONTAINER_SX, RESPONSE_STATUS, S3_PREFIX} from "../../libs/constants"
import {convertToDecimalString, getAuthenticatedUserId} from "../../libs/utils";

let interval
let pollCount = 0

export default class RunSearchTermReport extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      success: true,
      resMsg: "",
      mostRecentLastModified: 0,
      createdFiles: []
    }
  }

  fetchFiles = async () => {
    const {mostRecentLastModified} = this.state
    const {inputs} = this.props
    const expectedNumFiles = inputs.lowRoiFilterThresh.length > 0 ? 3 : 2
    console.log(`Fetching files: expecting ${expectedNumFiles} files`)
    await Storage.list(S3_PREFIX.OUTPUTS)
      .then(objects => {
          // skip first element of object array because it is the directory and not an actual file
          const newFiles = objects.results.map(object => ({
            key: object.key.split(S3_PREFIX.OUTPUTS)[1],
            lastModified: object.lastModified.toLocaleString('en-US'),
            size: object.size,
          })).sort((a, b) => new Date(b.lastModified) - new Date(a.lastModified)).slice(0, 100)

          // if fetch files for the first time, set mostRecentLastModified so we can compare with other files
          if (mostRecentLastModified === 0) {
            this.setState({mostRecentLastModified: new Date(newFiles[0].lastModified)})
          }

          // find the new files by comparing most recent 10 files with most recent last modified date
          const createdFiles = newFiles.slice(0, 10).filter(object => new Date(object.lastModified) > this.state.mostRecentLastModified)
          // check for 2 files or more, because the brand file is uploaded after the nonbrand file, and we don't want
          // to stop polling until both files are uploaded
          if (createdFiles.length >= expectedNumFiles) {
            console.log(`${createdFiles.length} newly created files detected, assuming run was successful. Newly created files: `, createdFiles)
            this.setState({
              createdFiles: createdFiles,
              processing: false
            })
          }
        }
      )
  }

  poll = () => {
    if (this.state.processing === false) {
      console.log('Processing is false during poll() terminating polling')
      clearInterval(interval)
      return
    }

    // API Gateway has a max timeout of 29s. Assume that all runs will take 3 minutes or less (180s).
    // Need to cover additional 150s with polling, i.e. 30 polling iterations spaced 5s apart.
    if (pollCount > 30) {
      console.log('Polled 28 times terminating polling')
      this.setState({processing: false})
      clearInterval(interval)
      this.handleError('Run timed out. Please report this to Nathan!')
      return
    }
    console.log('Polling poll count: ', pollCount)
    this.fetchFiles()
    pollCount++
  }

  startPolling = () => {
    if (this.state.processing === false) {
      console.log('processing is False when startPolling called returning early')
      return
    }
    console.log('API Gateway timeout exceeded, starting polling for output files')
    // poll once every 5 seconds for 6 iterations
    interval = setInterval(this.poll, 5000)
  }

  run = async () => {
    const {inputs} = this.props
    const {
      companyName,
      inputSourceFilename,
      adGroupReportFilename,
      companyNameVariations,
      productNames,
      negatives,
      prioritizedTokensInSlug,
      explicitCampaignTokens,
      equivalentTokens,
      lowRoiFilterColumn,
      lowRoiFilterThresh,
      nonTokensForKwe,
      maxTokenTierDepth,
      conversionThresh,
      costPercentileThresh,
      imprPercentileThresh,
      wordCountThresh,
      tokenCharLenThresh,
    } = inputs

    const userId = await getAuthenticatedUserId();
    const body = {
      // required fields
      userId,
      inputSourceFilename,
      companyName,
      // optional fields
      ...(adGroupReportFilename.length > 0 && {adGroupReportFilename}),
      ...(companyNameVariations.length > 0 && {companyNameVariations}),
      ...(productNames.length > 0 && {productNames}),
      ...(negatives.length > 0 && {negatives}),
      ...(prioritizedTokensInSlug.length > 0 && {prioritizedTokensInSlug}),
      ...(explicitCampaignTokens.length > 0 && {explicitCampaignTokens}),
      ...(equivalentTokens.length > 0 && {
        equivalentTokens: JSON.stringify(equivalentTokens
          .filter(row => row.length >= 2)
          .reduce((obj, row) => {
            obj[row[0]] = row.slice(1, row.length)
            return obj
          }, {}))
      }),
      // Low ROI filter configurations: add in POST body if threshold is provided (column value is initialized as "ROAS")
      ...(lowRoiFilterThresh.length > 0 && {lowRoiFilterColumn}),
      ...(lowRoiFilterThresh.length > 0 && {lowRoiFilterThresh}),
      ...(nonTokensForKwe.length > 0 && {nonTokensForKwe}),
      ...(maxTokenTierDepth.length > 0 && {maxTokenTierDepth}),
      ...(conversionThresh.length > 0 && {conversionThresh}),
      ...(costPercentileThresh.length > 0 && {costPercentileThresh: convertToDecimalString(costPercentileThresh)}),
      ...(imprPercentileThresh.length > 0 && {imprPercentileThresh: convertToDecimalString(imprPercentileThresh)}),
      ...(wordCountThresh.length > 0 && {wordCountThresh}),
      ...(tokenCharLenThresh.length > 0 && {tokenCharLenThresh}),
    }

    console.log('Submitting POST call with body: ', body)

    // API Gateway has a timeout limit of 30s. If POST responds successfully, just pull new output files
    // once. If it times out, start polling for 60s (i.e. 90s total for output files to be uploaded)
    this.setState({processing: true})


    await API.post(
      'ApiGatewayRestApi',
      '/search-term-report/process',
      {
        body,
        headers: {
          'Content-Type': 'application/json',
        }
      },
    ).then(res => {
      console.log('Response: ', res)
      if (!res.message || !res.message.includes(':')) {
        this.handleError('Run failed: response not properly structured')
      }
      const split = res.message.split(':')
      const response_status = split[0]
      // sad case: bad request or error
      // HACK ALERT: The backend always returns 200 instead of sensible status codes like 400 and 500
      // This allows us to get around the fact that AWS Amplify does not let us grab the response body when there
      // are non-200 responses
      if (response_status === RESPONSE_STATUS.BAD_REQUEST || response_status === RESPONSE_STATUS.ERROR) {
        this.handleError(res.message)
        // todo: can we refactor this by moving success up?
      } else if (response_status === RESPONSE_STATUS.SUCCESS) {
        // happy path: run finishes before 30s API Gateway timeout
        this.setState({processing: false})
        // fetch files again to get the updated files
        this.fetchFiles()
        console.log('POST request completed successfully: ', res.message)
      } else {
        console.log('> response_status: ', response_status)
        this.handleError(res.message)
        console.log('Error: unrecognized response_status: ', res.message)
      }
      this.setState({processing: false})
    }).catch(err => {
      console.log('Lambda execution resulted in an error: ', err)
      // sad path: start polling if we get a timeout error (status code 504)
      if (err.response.status === 504) {
        console.log('Received timeout error, starting polling')
        this.startPolling()
      } else {
        // this should never happen, because we try/catch most of the logic in the backend and return a 200 for
        // runtime errors
        console.log('Received non-timeout error, setting processing to False')
        this.handleError(err)
      }
    })
  }

  handleError = errMsg => {
    this.setState({
      processing: false,
      success: false,
      errMsg: errMsg,
    })
  }

  downloadFile = async filename => {
    const downloadUrl = await Storage.get(S3_PREFIX.OUTPUTS.concat(filename));
    window.location.href = downloadUrl
  }


  componentDidMount = () => {
    this.fetchFiles()
    this.run()
  }

  render() {
    const {inputs, prevStep, restart} = this.props
    const {processing, success, createdFiles} = this.state

    return (
      <Container id="run-complete-container"
                 maxWidth="md"
                 sx={CONTAINER_SX}>
        <Slide direction="right"
               timeout={400}
               in={true}>
          <div>
            {processing ?
              <Loading imgFilename="/donut-loading.gif"
                       pixelWidth="300px"
                       text="Creating Prizm file..."
              /> :
              <div id="run-complete-box">
                {success ?
                  <div>
                    <Loading imgFilename="/donut-success.gif"
                             pixelWidth="300px"
                             text={createdFiles.length > 0 ? "Your Prizm files are ready" : "Prizm run successful"}
                    />
                    {createdFiles.length > 0 ? createdFiles.map((file, idx) =>
                      <Box display="flex" pt="10px" key={idx}>
                        <Button pt="10px"
                                key={idx}
                                variant="text"
                                onClick={() => this.downloadFile(file.key)}
                                sx={{
                                  margin: "auto"
                                }}>
                          <Typography variant="body"
                                      color="#292A2A"
                                      sx={{
                                        fontSize: "24px",
                                        fontWeight: 500,
                                        paddingRight: "13px"
                                      }}>
                            {file.key}
                          </Typography>
                          <DownloadForOfflineIcon sx={{p: 0, color: "#292A2A"}}/>
                        </Button>
                      </Box>
                    ) : (
                      <Box display="flex" pt="10px">
                        <Typography variant="body"
                                    color="#292A2A"
                                    sx={{
                                      fontSize: "24px",
                                      fontWeight: 500,
                                      paddingRight: "13px",
                                      margin: "auto",
                                    }}>
                          No files were created - all keywords were probably filtered out
                        </Typography>
                      </Box>
                    )}
                  </div>
                  :
                  <div>
                    <Loading imgFilename="/donut-error.gif"
                             pixelWidth="300px"
                             text={`Something went wrong 😰`}
                    />
                    <Box display="flex" pt="10px">
                      <Typography variant="body"
                                  color="red"
                                  sx={{
                                    fontSize: "24px",
                                    fontWeight: 500,
                                    fontFamily: "monospace",
                                    margin: "auto",
                                  }}>
                        {this.state.errMsg}
                      </Typography>
                    </Box>
                  </div>
                }
                <Box display="flex" pt="46px">
                  <Box margin="auto">
                    <Button variant="outlined"
                            onClick={prevStep}
                            color="primary"
                            sx={{
                              boxShadow: 10,
                              marginRight: "25px",
                              color: "#36413A"
                            }}>
                      Re-run with different parameters
                    </Button>
                    <Button variant="contained"
                            onClick={restart}
                            color="secondary"
                            disabled={!inputs.inputSourceFilename}
                            sx={{
                              boxShadow: 10,
                            }}>
                      Home
                    </Button>
                  </Box>
                </Box>
              </div>
            }
          </div>
        </Slide>
      </Container>
    )
  }
}