import '@aws-amplify/ui-react/styles.css'
import React from "react"
import {withAuthenticator} from '@aws-amplify/ui-react'
import {useTheme} from '@mui/material/styles'
import {Box} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import Navbar from '../base/navbar'
import {TOAST_SEVERITY_STATUSES} from "../../libs/constants";
import CreativeParentForm from "./creative-parent-form";


function CreativeApp() {
  const [open, setOpen] = React.useState(false);
  const [toastMsg, setToastMsg] = React.useState("");
  const [toastSeverity, setToastSeverity] = React.useState(TOAST_SEVERITY_STATUSES.SUCCESS);

  const createToast = (msg, severity) => {
    setToastMsg(msg)
    setToastSeverity(severity)
    setOpen(true)
  }

  const theme = useTheme();

  const handleClose = (event, reason) => {
    // todo ?
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Box style={{backgroundColor: theme.palette.primary.main}}>
      <Navbar/>
      <CreativeParentForm createToast={createToast} theme={theme}/>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={toastSeverity} sx={{width: '100%'}}>
          {toastMsg}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default withAuthenticator(CreativeApp)
