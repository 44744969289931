export const KEYS = {
  // required fields
  COMPANY_NAME: "companyName",
  PRODUCT_DEFINITION: "productDefinition",
  VALUE_PROPS: "valueProps",
  TARGET_AUDIENCE: "targetAudience",
  OBJECTIVE: "objective",
  DURATION_SECONDS: "durationSeconds",

  // optional / hidden fields
  TONE: "tone",
  FOLLOWUP_REQUESTS: "followupRequests",
  CURRENT_FOLLOWUP_INPUT: "currentFollowupInput",
  COMPLETION_RESPONSE: "completionResponse",
  COPY_TYPE: "copyType",
  ARTICLE_TYPE: "articleType",
  POV: "pov",
}

export const requiredKeys = [
  [KEYS.COMPANY_NAME],
  [KEYS.PRODUCT_DEFINITION],
  [KEYS.VALUE_PROPS],
  [KEYS.TARGET_AUDIENCE],
  [KEYS.OBJECTIVE],
  [KEYS.DURATION_SECONDS],
];

export const COMPANY_DEFAULT_VALUES = {
  "1-800-Flowers": {
    [KEYS.COMPANY_NAME]: "1-800-Flowers",
    [KEYS.PRODUCT_DEFINITION]: "1-800-Flowers is a floral and gift retailer offering a wide range of flowers, bouquets, and other gifts for various occasions.",
    [KEYS.VALUE_PROPS]: [
      "Convenience: flowers and gifts delivered straight to your door (same-day delivery options available)",
      "Wide Product Range: plants, gourmet foods, and personalized gift baskets for any occasion",
      "Quality & Freshness: fresh, high-quality flowers arranged by local florists, backed by a satisfaction guarantee",
      "Emotional Connection: delivers smiles, makes people feel loved and appreciated, and celebrates life’s special moments.",
      "Innovative Gifting Solutions: constantly evolving product offerings, ways to incorporating personalized touches, and exclusive collections."
    ],
    [KEYS.TARGET_AUDIENCE]: "Women & Men ages 40 - 50",
    [KEYS.OBJECTIVE]: "Encourage people to explore the product range, take advantage of special offers, or place an order on 1800flowers.com",
    [KEYS.DURATION_SECONDS]: "45",
    [KEYS.TONE]: ["Persuasive", "friendly", "inspiring", "trustworthy"],
  },
  "Alan Meds": {
    [KEYS.COMPANY_NAME]: "Alan Meds",
    [KEYS.PRODUCT_DEFINITION]: "An online Telehealth platform that collaborates with independent physicians and health care practitioners whose aim is to help people achieve their weight management goals. Alan does this by matching you to high quality, state-licensed doctors who specialize in weight management for an affordable monthly price. Additionally, they provide resources for nutrition and lifestyle changes to help patients address weight management holistically.",
    [KEYS.VALUE_PROPS]: [
      "Tailored weight management plans (may include prescribed medication)",
      "One all-inclusive monthly price",
      "Fast and direct medication delivery",
      "Free overnight shipping",
      "Lower cost than Ozempic (high performer)",
      "Expert doctor guidance"
    ],
    [KEYS.TARGET_AUDIENCE]: "25-65",
    [KEYS.OBJECTIVE]: "online purchases",
    [KEYS.DURATION_SECONDS]: "45",
    [KEYS.TONE]: ["educational", "trustworthy"],
  },
  "Alleyoop": {
    [KEYS.COMPANY_NAME]: "Alleyoop",
    [KEYS.PRODUCT_DEFINITION]: "Makeup essentials that simplify your routine",
    [KEYS.VALUE_PROPS]: [
      "Travel-friendly & TSA-approved",
      "High-performing",
      "Award-winning",
      "Innovative design",
      "Made to reduce the amount of makeup products you have",
      "Made to reduce the time it takes to do your makeup",
      "Easy, fast, efficient makeup routine",
      "Makeup for on-the-go",
      "Clean, cruelty-free, vegan",
      "Made with ingredients that most skincare products have (ex: niacinamide)",
    ],
    [KEYS.TARGET_AUDIENCE]: "Women ages 25-35",
    [KEYS.OBJECTIVE]: "buy makeup on meetalleyoop.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["Conversational", "playful", "persuasive", "personal"],
  },
  "Arrae": {
    [KEYS.COMPANY_NAME]: "Arrae",
    [KEYS.PRODUCT_DEFINITION]: "Targeted natural supplements take a holistic approach to women’s health issues",
    [KEYS.VALUE_PROPS]: [
      "100% natural & organic ingredients",
      "Works in under an hour",
      "Clinically-backed",
      "Simple ingredients",
      "Trusted by naturopaths",
      "Filler-free, gluten-free, nut-free, non-GMO, vegan",
      "Bloat: works in under an hour, to eliminate belly bloat, soothe abdominal discomfort, and optimize digestion; clinically shown to reduce bloating by 86% through eliminating excess gas buildup",
      "Heartburn: works in under an hour, all-natural acid reflux relief, prevents & reduces heartburn, acid reflux, and indigestion, targets upper abdominal pain & acidic regurgitation, coats the esophagus to provide a barrier against stomach acid",
      "Sleep: all natural melatonin alternative, promotes restful & uninterrupted sleep, helps achieve deep & REM sleep, acts fast and also regulates sleep cycle over time, doesn’t cause morning drowsiness or dependency",
    ],
    [KEYS.TARGET_AUDIENCE]: "Women ages 24-34",
    [KEYS.OBJECTIVE]: "buy supplements on arrae.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["Conversational", "feminine", "persuasive", "personal"],
  },
  "Cameo": {
    [KEYS.COMPANY_NAME]: "Cameo",
    [KEYS.PRODUCT_DEFINITION]: "Platform that connects fans with their favorite celebrities, athletes, influencers, and other public figures for personalized video messages",
    [KEYS.VALUE_PROPS]: [
      "Direct connection with celebrities (get a personalized video from a celebrity)",
      "Personalized, customizable experiences (can fill out questionnaire for celeb to reference, no video is the same)",
      "memorabilia and keepsakes (watch as many time as you want)",
      "Convenient (fast turnaround, no shipping)",
      "Great gift (reactions are priceless and just as fun to give as to get)",
    ],
    [KEYS.TARGET_AUDIENCE]: "fans of celebrities, gift givers, social media enthusiasts",
    [KEYS.OBJECTIVE]: "buy a personalized Cameo for friends, family, and/or loved one on their website",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["funny", "outlandish", "witty", "attention grabbing"],
  },
  "Caraway": {
    [KEYS.COMPANY_NAME]: "Caraway",
    [KEYS.PRODUCT_DEFINITION]: "Non-toxic, high-quality kitchen essentials",
    [KEYS.VALUE_PROPS]: [
      "4 different kinds of kitchen essentials: cookware, bakeware, prep set & cutting boards, food storage",
      "All products have the same value props, listed below",
      "100% non-toxic, non-stick ceramic coated surface",
      "Makes cooking & cleaning so much easier",
      "Comes with complimentary storage organizer",
      "Comes in so many different colors",
      "Elevates your kitchen",
      "Declutters your kitchen",
      "Easy to store or beautiful to display",
    ],
    [KEYS.TARGET_AUDIENCE]: "Women ages 25-55",
    [KEYS.OBJECTIVE]: "buy kitchen essentials on carawayhome.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["Conversational", "aspirational", "persuasive"],
  },
  "Checkmate": {
    [KEYS.COMPANY_NAME]: "Checkmate",
    [KEYS.PRODUCT_DEFINITION]: "Checkmate is an innovative online shopping assistant that helps users save money and time by automatically applying the best coupon codes and cashback offers at checkout. It also tracks prices and provides alerts when there are price drops on desired items.",
    [KEYS.VALUE_PROPS]: [
      "Automatically applies the best available coupon codes at checkout.",
      "Provides cashback offers to maximize savings.",
      "Tracks prices and alerts users of price drops.",
      "Simplifies the online shopping experience by integrating seamlessly with most online retailers.",
      "Saves time and money by eliminating the need to manually search for discounts."
    ],
    [KEYS.TARGET_AUDIENCE]: "Online shoppers looking to save money and time. Bargain hunters who frequently search for discounts and deals. Tech-savvy consumers who use browser extensions and apps to enhance their shopping experience. People who shop across various online retailers and want a streamlined, efficient checkout process. Primary: Women, 18-30, frequent shoppers at low to mid-AOV stores: Princess Polly, Alo Yoga, etc., some fashion forward high AOV: Ssense, Ganni. Secondary: Men, 18-30, frequent shoppers at low to mid-AOV stores: Dickies, ASOS, etc.",
    [KEYS.OBJECTIVE]: "Increase brand awareness and adoption of the Checkmate tool. Highlight the ease of use and significant savings provided by the tool. Drive installations of the Checkmate browser extension and app. Showcase customer testimonials and success stories to build trust and credibility.",
    [KEYS.DURATION_SECONDS]: "45",
    [KEYS.TONE]: ["Friendly and helpful", "Trustworthy and credible", "Excited and enthusiastic about the savings potential", "Clear and informative to ensure the benefits are easily understood"],
  },
  "Clevr": {
    [KEYS.COMPANY_NAME]: "Clevr",
    [KEYS.PRODUCT_DEFINITION]: "Better-than-the-coffee shop instant adaptogenic lattes that are better FOR you, too. Enhanced with adaptogens, probiotics, and superfoods for various health benefits (focus and energy without a crash, de-stress, deep sleep).",
    [KEYS.VALUE_PROPS]: [
      "Designed by Baristas: Just like your coffee shop favorites: perfect fluffy microfoam, and a creamy decadent taste, super premium teas, coffee & spices",
      "Truly instant: We did the hard work for you - oat milk & coconut SuperCreamer is already inside. Rich and creamy with just water.",
      "Better for you: No dairy, refined sugar, or fillers. Only 80 calories — unlike cafe lattes that are overloaded with sugar at at least 240 calories.",
      "Packed with Perks: Soothe stress, improve your sleep, and boost your mood thanks to adaptogens, probiotics and superfoods in every scoop.",
      "Science & clinically backed: We use clinically tested, science backed doses of our key ingredients (no sprinkling here!). We run our own clinical trials to prove efficacy.",
      "Cost-saving: $2.00 / latte instead of the $6 at most coffee shops.",
      "Thoughtfully and responsibly sourced ingredients",
      "Trust elements: Featured in Oprah's favorite things, loved by Meagan Markle and Dr. Mark Hyman"
    ],
    [KEYS.TARGET_AUDIENCE]: "females 25-60",
    [KEYS.OBJECTIVE]: "Buy lattes on clevr.com",
    [KEYS.DURATION_SECONDS]: "45",
    [KEYS.TONE]: ["Opinionated", "comforting", "forward", "clear"],
  },
  "Dime Beauty": {
    [KEYS.COMPANY_NAME]: "Dime Beauty",
    [KEYS.PRODUCT_DEFINITION]: "Luxurious skincare products that are clean, effective, and approachable",
    [KEYS.VALUE_PROPS]: [
      "Clean ingredients",
      "Approachable luxury",
      "Vegan & cruelty free",
      "TBT cream: get “retinol results” without the irritation, purging, or sun sensitivity. A clean retinol alternative that’s gentle & effective, anti-aging benefits, helps accelerate skin cell turnover, assists in plumping & firming the skin, improves the appearance of fine lines & wrinkles, helps regulate sebum production",
    ],
    [KEYS.TARGET_AUDIENCE]: "women 30+",
    [KEYS.OBJECTIVE]: "buy skincare products on dimebeautyco.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["personable", "conversational", "persuasive"],
  },
  "EveryPlate": {
    [KEYS.COMPANY_NAME]: "EveryPlate",
    [KEYS.PRODUCT_DEFINITION]: "affordable meal kit",
    [KEYS.VALUE_PROPS]: [
      "Affordable, cheaper than grocery shopping",
      "Best value meal kit",
      "Sent right to your door",
      "Replaces grocery shopping & meal prep, saves time",
      "$1.49 per meal + $1 steak for life (while promo is going on)",
      "Recipes are easy to follow",
      "Choose from 26 delicious recipes that change every week",
      "30 minute prep + cook time",
    ],
    [KEYS.TARGET_AUDIENCE]: "men and women 18+",
    [KEYS.OBJECTIVE]: "buy/subscribe to meal kits on everyplate.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["conversational", "persuasive", "humorous"],
  },
  "First Person": {
    [KEYS.COMPANY_NAME]: "First Person",
    [KEYS.PRODUCT_DEFINITION]: "Precision-targeted functional mushroom supplements that support your brain health",
    [KEYS.VALUE_PROPS]: [
      "Supports long-term brain health",
      "Targets your body's natural production of dopamine, oxytocin, & GABA",
      "Helps you access your best mind & reach peak cognition",
      "3 blends: Sunbeam (Focus, motivation, productivity, creativity, learning, long-term mental stamina),  Golden Hour (Stress-reduction, socializing, confidence, mood, joy, long-term memory), Moonlight (deep sleep, clear mornings, no waking up at night)",
      "Fills the holes in your diet to give your brain the nutrients it needs",
      "Science-backed: Formulated by industry-leading scientists",
      "Made with 100% natural ingredients like functional mushrooms and other superfoods",
      "Award-winning",
    ],
    [KEYS.TARGET_AUDIENCE]: "Men and women 35+",
    [KEYS.OBJECTIVE]: "buy supplements on getfirstperson.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["Informational", "science-backed", "credible", "conversational", "personal", "aspirational"],
  },
  "Futurewise": {
    [KEYS.COMPANY_NAME]: "Futurewise",
    [KEYS.PRODUCT_DEFINITION]: "The first-ever system of skincare products designed specifically for slugging — to strengthen your skin barrier for healthy & hydrated skin",
    [KEYS.VALUE_PROPS]: [
      "providing hydration and barrier repair",
      "for plump, juicy, healthy skin",
      "made with natural, dermatologist-approved ingredients",
      "Award-winning",
    ],
    [KEYS.TARGET_AUDIENCE]: "Predominantly women 25+",
    [KEYS.OBJECTIVE]: "Buy the cult-favorite Slug System on futurewise.world",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["trendy", "cheeky"],
  },
  "G FUEL": {
    [KEYS.COMPANY_NAME]: "G FUEL",
    [KEYS.PRODUCT_DEFINITION]: "Energy Tubs - Is the ultimate fuel for gamers, creators, students, and athletes. Made with top-tier ingredients, our sugar-free formulas give you the energy, focus, and endurance you need to power through your day. Protein Powder — Has 140 mg of caffeine and 15 g of whey protein per serving, this 2-in-1 formula from the G FUEL Innovation Labs has got your back no matter what’s in store for your day. Gfuel Hydration — Has zero caffeine, zero sugar, and zero calories and is formulated for both Hydration & Recovery.",
    [KEYS.VALUE_PROPS]: [
      "Energy Tubs — 0 SUGAR. Unlike our “competitors” who rely on loads of sugar to provide you with a false sense of energy, we don’t – Which means zero “crash”. ENERGY COMPLEX — Caffeine is a natural stimulant consumed worldwide. The primary benefit is that of cognitive function stimulation - Essentially giving your mind a “jump start” when it needs it most. FOCUS AMINO-FORTIFIED — Our addition of a focus amino provides you with a unique edge that most other drinks on the market cannot. PACKED WITH ANTIOXIDANTS — Just the right balance of antioxidants that work in tandem with our vitamin complex to promote healthy cell production. VITAMIN-FORTIFIED — Our formula contains the perfect combination and subtle ratio of Vitamins C + E + B12 + B6",
      "Protein Powder — Boosts Metabolism, Clean Energy, 0 Sugar, Improves Focus, Supports Immunity. Gfuel Hydration — 0 Sugar + 0 Calories, Electrolyte Enhanced, Vitamin Fortified, HYDRATION + IMMUNITY + FOCUS + RECOVERY",
    ],
    [KEYS.TARGET_AUDIENCE]: "Primarily male gamers, age 18-25",
    [KEYS.OBJECTIVE]: "Drive more sales on gfuel.com",
    [KEYS.DURATION_SECONDS]: "60",
    [KEYS.TONE]: ["enthusiastic", "excited", "inclusive"],
  },
  "Honey Pot": {
    [KEYS.COMPANY_NAME]: "Honey Pot",
    [KEYS.PRODUCT_DEFINITION]: "The first plant-derived vaginal care system",
    [KEYS.VALUE_PROPS]: [
      "Gynecologist and Dermatologist-Approved",
      "Helps Maintain a Healthy Vaginal pH range",
      "Formulated without fragrances, pesticides, chlorine",
      "94% agreed our foaming wash improves intimate skin health*",
      "Gentle and Non-irritating",
      "Safe for and Kind to skin",
    ],
    [KEYS.TARGET_AUDIENCE]: "Women 25-44",
    [KEYS.OBJECTIVE]: "Buy wellness products online",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["relatable", "informative", "a little cheeky"],
  },
  "Hu": {
    [KEYS.COMPANY_NAME]: "Hu",
    [KEYS.PRODUCT_DEFINITION]: "Hu offers premium, better-for-you, simple ingredient chocolate bars and chocolate chips that taste delicious. Their dark chocolate bar variety pack is a great way to try multiple flavors and increase customer AOV, so often is the focus of concepts.",
    [KEYS.VALUE_PROPS]: [
      "Simple, quality ingredients (no refined sugar, organic, fairtrade)",
      "Indulgent, great taste",
      "It's important to avoid any direct health claims, but they want to position themselves as a better-for-you alternative to other brands",
    ],
    [KEYS.TARGET_AUDIENCE]: "75% female, age 25-44, $100K+ household income, DINK & young families",
    [KEYS.OBJECTIVE]: "Get people to buy Hu chocolate online directly from their site",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["relatable", "informative", "a little cheeky"],
  },
  "Jaanuu": {
    [KEYS.COMPANY_NAME]: "Jaanuu",
    [KEYS.PRODUCT_DEFINITION]: "focusing on the medical apparel market, Jaanuu offers a range of products including scrubs, scrub jackets, underscrubs, and face masks, all designed for comfort and performance",
    [KEYS.VALUE_PROPS]: [
      "All day comfortability (perfect fit, mobility)",
      "extremely durable yet stylish (quality materials)",
      "feel confident in how you look while remaining comfortable all day long",
      "trend-setting designs and colors in the HCP space",
      "functional (pockets)",
    ],
    [KEYS.TARGET_AUDIENCE]: "health care providers (doctors, nurses, vet-techs, dental hygienist, etc.)",
    [KEYS.OBJECTIVE]: "buy Jaanuu’s scrubs on their website",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["educational", "persuasive"],
  },
  "MeUndies": {
    [KEYS.COMPANY_NAME]: "MeUndies",
    [KEYS.PRODUCT_DEFINITION]: "high quality, comfortable underwear with a huge variety of prints",
    [KEYS.VALUE_PROPS]: [
      "The world’s most comfortable underwear",
      "The most premium micromodal fabric, which feels 3x softer than cotton",
      "Available in sizes XS-4XL, designed for every shape & size",
      "High quality, sustainably-sourced fabrics",
      "Underwear is durable, doesn’t shrink & feels softer with every wash",
      "Breathable and moisture-wicking",
      "Members-only pricing, save up to 30% on every purchase",
      "Huge variety of prints",
      "Free shipping",
    ],
    [KEYS.TARGET_AUDIENCE]: "men & women 18+",
    [KEYS.OBJECTIVE]: "buy meundies underwear on meundies.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["personable", "authentic", "humorous", "persuasive"],
  },
  "Mill": {
    [KEYS.COMPANY_NAME]: "Mill",
    [KEYS.PRODUCT_DEFINITION]: "Mill offers a revolutionary kitchen bin that recycles food waste overnight. It shrinks food by 80%, minimizing the frequency of taking out the trash and eliminating odors with advanced filtration.",
    [KEYS.VALUE_PROPS]: [
      "significantly reduces food waste and related expenses",
      "supports environmental sustainability",
      "simplifies waste management in homes",
    ],
    [KEYS.TARGET_AUDIENCE]: "Environmentally conscious consumers and those seeking efficient and odorless food waste management solutions",
    [KEYS.OBJECTIVE]: "To promote the Mill kitchen bin as a convenient, sustainable, and odorless solution for managing food waste at home",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["innovative", "focusing on practicality, sustainability, and ease of use"],
  },
  "Nood": {
    [KEYS.COMPANY_NAME]: "Nood",
    [KEYS.PRODUCT_DEFINITION]: "IPL device that permanently removes body hair",
    [KEYS.VALUE_PROPS]: [
      "Permanent hair removal",
      "At-home treatments",
      "Easy 10-minute treatments twice a week",
      "Pain-free",
      "Works anywhere hair grows",
      "FDA-approved IPL technology",
      "Skin-safe",
      "90-day money-back guarantee",
      "Trusted by over 300,000 users",
    ],
    [KEYS.TARGET_AUDIENCE]: "Broad",
    [KEYS.OBJECTIVE]: "buy the IPL device on trynood.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["Humorous", "to-the-point", "shocking", "conversational", "witty", "playful"],
  },
  "Ollie": {
    [KEYS.COMPANY_NAME]: "Ollie",
    [KEYS.PRODUCT_DEFINITION]: "Ollie delivers fresh, healthy dog food made with real, human-grade ingredients, tailored to your pup's unique nutritional needs.",
    [KEYS.VALUE_PROPS]: [
      "Health: Improved digestion, less-smelly poops, a softer coat, and increased energy.",
      "Quality: Crafted with delicious, human-grade meats, fruits, and veggies that are slow-cooked to perfection.",
      "Convenience: Delivered directly to your doorstep, however often your pup needs it. Every freshly-cooked meal is also pre-portioned and flash-frozen for easy, hassle-free feeding. Just defrost, scoop, serve, and done.",
      "Customizable: Ollie creates a customized meal plan based on what your dog wants and needs.",
    ],
    [KEYS.TARGET_AUDIENCE]: "30+ Females, 100k+ HH income",
    [KEYS.OBJECTIVE]: "Purchase a box from myollie.com",
    [KEYS.DURATION_SECONDS]: "45",
    [KEYS.TONE]: ["Lighthearted", "fun", "educational", "caring"],
  },
  "Pie": {
    [KEYS.COMPANY_NAME]: "Pie",
    [KEYS.PRODUCT_DEFINITION]: "Block ads, get paid. Simply browse ad-free with Pie Adblock. You deserve to get paid if you opt into ads. Also is a shopping extension that shows you the best coupons.",
    [KEYS.VALUE_PROPS]: [
      "Combo of Ad blocker + coupon stuff - basically an ad blocker that pays you ($0.01/ad), and shows you best shopping coupons",
      "Cashback Model - giving consumers slice of ad revenue",
      "There are 2 extensions",
      "“the new browser for the people”",
      "“don’t use a boomer browser”",
      "3 total products = 2 browser extensions (ad block and coupon/shopping) and mobile safari extension (only need to download extension once, but need to download the mobile app)"
    ],
    [KEYS.TARGET_AUDIENCE]: "Anyone who uses the internet and is interested in coupons/gets annoyed with Ads",
    [KEYS.OBJECTIVE]: "Drive installations of the pie chrome extension. Also to cross-sell so that people have both extensions.",
    [KEYS.DURATION_SECONDS]: "60",
    [KEYS.TONE]: ["playful", "organic", "conversational", "informative"],
  },
  "Playbook": {
    [KEYS.COMPANY_NAME]: "Playbook",
    [KEYS.PRODUCT_DEFINITION]: "financial planning app that helps minimize your taxes every year and boost your long term investment returns",
    [KEYS.VALUE_PROPS]: [
      "The only app that truly does it all: tax strategy, investing strategy, and financial planning.",
      "Only takes a few minutes to get started",
      "Calculates your optimal tax strategy",
      "Builds a comprehensive financial plan",
      "Automatically invests, saves, and optimizes your money for you",
      "Can increase your future net worth at retirement by about $1.3 million",
      "Playbook grows with you, easy to make adjustments any time",
      "First in the industry to offer a Smart Money Guarantee, including a 2x ROI guarantee",
    ],
    [KEYS.TARGET_AUDIENCE]: "Based in the US, 25-45 years old, Income $100K+, beginner investing experience, looking for a holistic approach to managing their investments",
    [KEYS.OBJECTIVE]: "paid sign ups for their Essential and Plus plans",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["conversational", "informative", "down-to-earth"],
  },
  "Prose": {
    [KEYS.COMPANY_NAME]: "Prose",
    [KEYS.PRODUCT_DEFINITION]: "personalized haircare",
    [KEYS.VALUE_PROPS]: [
      "Made-to-order formulas",
      "Entire hair routine personalized for you",
      "Customized haircare based on hair type, hair density and porosity, hair goals, environment, etc.",
      "Free of parabens, minerals, sulfates, phthalates and GMOs",
      "Safe to use on keratin-treated or color-treated hair",
      "Safe and sustainable ingredients",
    ],
    [KEYS.TARGET_AUDIENCE]: "women 18+",
    [KEYS.OBJECTIVE]: "subscribe to prose haircare",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["personable", "organic", "conversational"],
  },
  "Ritual": {
    [KEYS.COMPANY_NAME]: "Ritual",
    [KEYS.PRODUCT_DEFINITION]: "Daily supplements for everyday health",
    [KEYS.VALUE_PROPS]: [
      "100% clean, vegan, non-GMO filler-free",
      "Traceable ingredients",
      "Clinically-backed",
      "Third-party tested",
      "Fills the holes in your current diet",
      "30-day money-back guarantee",
      "Designed for optimal absorption",
      "Naturally flavored for a delicious taste & smell",
    ],
    [KEYS.TARGET_AUDIENCE]: "Women 25-55",
    [KEYS.OBJECTIVE]: "buy supplements on ritual.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["conversational", "informative", "personal", "science-backed"],
  },
  "Rise": {
    [KEYS.COMPANY_NAME]: "Rise",
    [KEYS.PRODUCT_DEFINITION]: "RISE is an energy and sleep tracker subscription app created by the team of sleep experts at Rise Science. It helps you unlock better sleep and enjoy more energy, day after day. The app is a sleep tracker, gentle alarm clock, and your science-backed guide to getting a good night’s sleep. It tells you the best time to do everything, from when to have your final cup of coffee to when you should wind down for bed, all based on your own biology. It also predicts your energy cycle each day and teaches you how to harness it.",
    [KEYS.VALUE_PROPS]: [
      "Rise is the sleep app that actually helps you become a better sleeper",
      "Founded by scientists from Harvard & Stanford",
      "Become a morning person",
      "Track the sleep you owe your body",
      "Reduce your sleep debt",
      "Improve your mood, metabolism, immunity, libido, and productivity",
      "Plan your day around your energy levels",
      "Build healthy habits",
      "Learn the science behind your sleep needs"
    ],
    [KEYS.TARGET_AUDIENCE]: "Men & women ages 25-35",
    [KEYS.OBJECTIVE]: "Drive downloads & subscriptions",
    [KEYS.DURATION_SECONDS]: "35",
    [KEYS.TONE]: ["Persuasive", "scientific", "aggressive"],
  },
  "Ruggable": {
    [KEYS.COMPANY_NAME]: "Ruggable",
    [KEYS.PRODUCT_DEFINITION]: "Ruggable’s patented Rug System comes in two pieces: a lightweight Rug Cover and a non slip Rug Pad. Lightweight, Washable Rug Cover: Low-pile and lightweight Rug Cover is stain-resistant, water-resistant, and designed to fit conveniently in your home washing machine—even the 9x12! Non Slip Rug Pad: Easy-to-clean Rug Pad features a nonslip back, which is made of the same latex-free material used in yoga mats.",
    [KEYS.VALUE_PROPS]: [
      "Designed for life: because the product is washable, it is designed to handle messes that inevitably happen from life.",
      "Ease of use: two-part system is quick and easy to set up.",
      "Versatility: two-part system makes it so you can change out the rug cover when you want to change your space up rather than buying a whole new rug.",
      "Longevity: Because the cover is washable, you’ll be able to keep this rug for years."
    ],
    [KEYS.TARGET_AUDIENCE]: "Men & women 25-65",
    [KEYS.OBJECTIVE]: "increase engagement on TikTok",
    [KEYS.DURATION_SECONDS]: "35",
    [KEYS.TONE]: ["Optimistic", "encouraging", "fun"],
  },
  "Stasis": {
    [KEYS.COMPANY_NAME]: "Stasis",
    [KEYS.PRODUCT_DEFINITION]: "Supplement designed to maintain brain balance when taken with stimulants (i.e. Adderall, Vyvanse, caffeine)",
    [KEYS.VALUE_PROPS]: [
      "All day support, from day to night to reduce stimulant downsides.",
      "Mood boost",
      "Fight stress",
      "Steady focus",
      "Restful sleep",
      "Promotes calm",
      "Brain health",
    ],
    [KEYS.TARGET_AUDIENCE]: "Target Gen Z and Millennials experiencing focus and attention challenges, interested in cognitive enhancement, and seeking alternative solutions beyond traditional stimulants.  Primarily female in the 35-44 age range, secondary ages 25-34.",
    [KEYS.OBJECTIVE]: "Buy the Day & Night set",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["Empowering", "scientific", "emphasizing personal growth and mental performance"],
  },
  "Salt & Stone": {
    [KEYS.COMPANY_NAME]: "Salt & Stone",
    [KEYS.PRODUCT_DEFINITION]: "Salt & Stone is skincare and self-care for a life lived in motion. Built on and from a connection to the natural world, we combine active ingredients from the sea and mountains with skin science and intoxicating fragrances, for a sensorial experience that soothes and restores. Each product is a careful blend of antioxidant and nutrient-rich ingredients—including seaweed, spirulina, kelp—created to protect, cleanse, support, and recover.",
    [KEYS.VALUE_PROPS]: [
      "Natural skincare products",
      "Active ingredients from the sea & mountains",
      "Blend of antioxidant and nutrient-rich ingredients created to protect, cleanse, support and recover",
      "Unisex, luxury, intoxicating fragrances",
      "Sensorial experience that soothes and restores",
      "Vegan, cruelty-free, and leaping bunny certified"
    ],
    [KEYS.TARGET_AUDIENCE]: "Women and Men age 25+",
    [KEYS.OBJECTIVE]: "Purchase products from saltandstone.com",
    [KEYS.DURATION_SECONDS]: "60",
    [KEYS.TONE]: ["Aspirational", "trustworthy", "organic"]
  },
  "Tally": {
    [KEYS.COMPANY_NAME]: "Tally",
    [KEYS.PRODUCT_DEFINITION]: "Longevity supplements, at-home epigenetic age tests, and personalized digital coaching. These products aim to slow aging at a cellular level, using supplementation and lifestyle changes based on individual DNA.",
    [KEYS.VALUE_PROPS]: [
      "Help slow aging",
      "Boost your performance at a cellular level",
      "protect against all 12 hallmarks of aging",
      "enhance mitochondrial health",
      "improve glucose metabolism",
      "mimic caloric restriction",
      "boost muscle function and performance",
      "Discover your true, epigenetic age with a cheek swab test",
      "Get personalized digital coaching based on your DNA",
    ],
    [KEYS.TARGET_AUDIENCE]: "Individuals interested in proactive, science-based health management, focusing on longevity and aging. 24-44 years old, split evenly between male and female, concentrated around cities, and with interests skewing towards tech, media, and finance.",
    [KEYS.OBJECTIVE]: "Promote the benefits of Tally Health’s products in controlling and improving the aging process through advanced scientific methods",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["optimistic", "scientific", "focusing on empowerment in managing your aging process through personalized health strategies"],
  },
  "Tempo": {
    [KEYS.COMPANY_NAME]: "Tempo",
    [KEYS.PRODUCT_DEFINITION]: "Tempo is a human-inspired virtual training experience that combines proven strength training methodologies with the latest AI technology to give more people the results of personal training. At Tempo, we believe strength training is the foundation to any active journey. It is the most universally beneficial fitness choice for any level, body type, or goal.",
    [KEYS.VALUE_PROPS]: [
      "Form feedback & real-time guidance: Tempo uses 3D sensor technology to track your range of motion, track reps, and to give users real-time form feedback and weight recommendations",
      "Coaching and Content: Multiple different coaches with their own personalities and styles with a variety of different training, ranging from strength training, HIIT, Yoga, mobility & recovery",
      "Convenience (main talking point): Ability to workout at home and avoid the gym and commute. Avoid the weight room anxiety, gym creepers, fear of judgment",
      "Accountability: Most people give up too early to establish a healthy fitness habit, therefore never getting their desired fitness results. With Tempo, you’ll get a virtual coach that makes you accountable to show up for your workouts",
      "Personalized Training: Get personalized training programs and guidance from the comfort of your home for a fraction of the price of what personal trainers charge. Tempo uses technology to measure and track your progress so that you can hit your fitness goals",
      "Affordability: Virtual Personal Training for $39/mo compared to average $65/hr PT rate",
      "Adaptable Planning: Personalized planning that adapts to your performance and modifies for injury or pregnancy",
    ],
    [KEYS.TARGET_AUDIENCE]: "Females, 30-50",
    [KEYS.OBJECTIVE]: "Drive more sales on Tempo.fit",
    [KEYS.DURATION_SECONDS]: "60",
    [KEYS.TONE]: ["Inspiring", "relatable", "inclusive"],
  },
  "Thesis": {
    [KEYS.COMPANY_NAME]: "Thesis",
    [KEYS.PRODUCT_DEFINITION]: "Personalized nootropics for cognitive enhancement. The products are tailored to individual needs based on a quiz, aiming to improve focus, motivation, and mental clarity.",
    [KEYS.VALUE_PROPS]: [
      "Personalized nootropic blends tailored to individual brain chemistry and goals.",
      "Improvement in energy levels, motivation, focus, and clarity of thought.",
      "A scientific approach to formulation, using clinically studied dosages and lab-tested ingredients.",
      "The opportunity to optimize blends with the help of wellness coaches.",
    ],
    [KEYS.TARGET_AUDIENCE]: "Individuals seeking cognitive enhancement and personalized supplement solutions. 25-45 years old, split evenly between male and female - concentrated around cities.",
    [KEYS.OBJECTIVE]: "Take the quiz and get the Starter Kit",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["empowering", "scientific", "emphasizing personal growth and mental performance"],
  },
  "Wildgrain": {
    [KEYS.COMPANY_NAME]: "Wildgrain",
    [KEYS.PRODUCT_DEFINITION]: "Wildgrain is a subscription-based delivery service that offers artisanal, slow-fermented, and ready-to-bake bread, pasta, and pastries.",
    [KEYS.VALUE_PROPS]: [
      "Health: sourdough benefits + simple ingredients",
      "Convenience: fresh, warm baked goods at home",
      "Quality: artisanal baking methods that are hard to replicate at home",
      "Small Business: support small artisan bakers"
    ],
    [KEYS.TARGET_AUDIENCE]: "Moms/females 30-65",
    [KEYS.OBJECTIVE]: "Customize and purchase a box from wildgrain.com",
    [KEYS.DURATION_SECONDS]: "45",
    [KEYS.TONE]: ["Friendly", "genuine", "passionate", "educational"],
  },
  "ZenBusiness": {
    [KEYS.COMPANY_NAME]: "ZenBusiness",
    [KEYS.PRODUCT_DEFINITION]: "One stop shop for starting, running and growing your business",
    [KEYS.VALUE_PROPS]: [
      "LLC formation",
      "Ease: lean into their 'EASE' differentiators (easiest, all-in-one platform, success-driven and equitable). Focus on how they're the easiest, all-in-one platform for people from all walks of life to start, run, and grow a successful business",
      "All-In-One: full lineup of services and support (including accounting, taxes, formation, marketing, system of record)",
      "Customer Support: their customer support team is available to help answer questions and give peace of mind",
      "Protection: ZenBusiness helps people feel more protected and be more successful",
      "Equitable: they have efforts on being equitable by supporting business owners from all walks of life (underrepresented, etc). Can highlight our grant program and that we're a public benefit corporation",
      "Help with 'scary'/'risky' things: can call out formation services that are legally required like a registered agent, employer ID number, annual compliance filings, and the business ownership information (BOI) report. These are those “scary” or “risky” areas that a lot of their customers don’t want to deal with"
    ],
    [KEYS.TARGET_AUDIENCE]: "25-44, indexes more male, but would like a healthy mix between male/female creators",
    [KEYS.OBJECTIVE]: "input information on zenbusiness.com",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["serious", "helpful", "approachable"],
  },
  "Zygo": {
    [KEYS.COMPANY_NAME]: "Zygo",
    [KEYS.PRODUCT_DEFINITION]: "Zygo has the world's first underwater headset that allows you to stream from your phone while swimming. Typically bluetooth doesn't work underwater (so you have to preload music), but their headset gets around this. You can listen to spotify, audiobooks, sports, literally anything on your phone. You can also get real time coaching straight to you from the poolside via a walkie-talkie like function. They also have an app that allows you to track workouts and optionally subscribe to guided, regularly published workouts.",
    [KEYS.VALUE_PROPS]: [
      "Stream anything from phone while you swim",
      "Get motivated to swim more often",
      "Bone conduction headset(ears free)",
      "Real time coaching via walkie talkie like feature",
      "Guided workouts in app",
      "Track workouts in the app",
    ],
    [KEYS.TARGET_AUDIENCE]: "Swimmers of all levels from pro to novice, people who want to get into swimming, friends and family of swimmers",
    [KEYS.OBJECTIVE]: "Educate the audience on the unique features of Zygo and show the many use cases in order to generate purchases on their site.",
    [KEYS.DURATION_SECONDS]: "30",
    [KEYS.TONE]: ["educated", "relatable", "authentic", "slightly witty"],
  },
}

export const COMPANY_NAMES = Object.keys(COMPANY_DEFAULT_VALUES).sort();
