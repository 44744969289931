import {Box, Typography} from "@mui/material";
import React from "react";

export default function Loading({imgFilename, pixelWidth, text}) {
  return (
    <Box id="loading-box">
      <Box display="flex" pt="134px">
        <Box m="auto">
          <img src={imgFilename} alt="loading gif" width={pixelWidth} height="auto"/>
        </Box>
      </Box>
      <Box display="flex" pt="38px">
        <Typography variant="h3" component="h1" margin="auto">
          {text}
        </Typography>
      </Box>
    </Box>
  )
}