// reference: https://blog.theashishmaurya.me/how-to-create-a-tag-input-feature-in-reactjs-and-material-ui
import {TextField} from "@mui/material";
import {Box} from "@mui/material";
import {useRef} from "react";
import Tags from "./tags";

export default function InputTags({
                                    inputs,
                                    handleChange,
                                    inputKey,
                                    placeholder,
                                    objectName = "tags",
                                    autoFocus = false
                                  }) {
  const tags = inputs[inputKey]
  const tagRef = useRef();

  const handleDelete = value => {
    const newTags = tags.filter((val) => val !== value);
    handleChange(inputKey)({
      target: {
        value: newTags
      }
    })
  };
  const handleOnSubmit = e => {
    e.preventDefault();
    // Don't allow the user to create empty tags by pressing "Enter" without first entering a string
    if (tagRef.current.value.length === 0) {
      return
    }
    handleChange(inputKey)({
      target: {
        value: [
          ...tags,
          ...tagRef.current.value
            // Convert a text value with multiple lines into individual tags
            .split('\n')
            .map(ele => ele.trim())
            .filter(ele => ele.length > 0)
        ]
      }
    })

    tagRef.current.value = "";
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleOnSubmit(event)
    }
  };

  return (
    <Box sx={{flexGrow: 1}}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          inputRef={tagRef}
          autoFocus={autoFocus}
          fullWidth
          variant='standard'
          size='small'
          sx={{margin: "1rem 0"}}
          margin='none'
          placeholder={tags.length < 3 ? `Enter ${objectName} (${placeholder})` : ""}
          InputProps={{
            startAdornment: (
              <Box sx={{margin: "0 0.2rem 0 0", display: "flex"}}>
                {tags.map((data, index) => {
                  return (
                    <Tags data={data} handleDelete={handleDelete} key={index}/>
                  );
                })}
              </Box>
            ),
          }}
          // The following props enable the user to paste a multiline piece of text and press enter to create multiple tags split by newline characters
          multiline
          onKeyDown={handleKeyDown}
        />
      </form>
    </Box>
  );
}
