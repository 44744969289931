import React, {useState, useEffect} from 'react';
import {Box, FormControlLabel, FormLabel, Switch, Typography} from '@mui/material';
import {KEYS} from '../../libs/company-data';
import {LANDING_PAGE_ADVERTORIAL_TYPES, POV_TYPES} from "../../libs/constants";

function DoubleLabeledSwitch({checked, onChange, leftLabel, rightLabel}) {
  return (
    <Box display="flex" alignItems="center">
      <Box style={{minWidth: "150px"}}>{leftLabel}</Box>
      <FormControlLabel
        control={<Switch
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
          color="info"
        />}
        label={rightLabel}
        labelPlacement="end"
      />
    </Box>
  );
}

export default function LandingPagePrompts(inputs) {
  const [articleType, setArticleType] = useState(inputs[KEYS.ARTICLE_TYPE] || LANDING_PAGE_ADVERTORIAL_TYPES.GENERAL);
  const [pov, setPov] = useState(inputs[KEYS.POV] || POV_TYPES.THIRD);

  // Synchronize state to inputs object when component mounts and articleType changes
  useEffect(() => {
    inputs[KEYS.ARTICLE_TYPE] = articleType;
  }, [articleType, inputs]);

  const handleArticleTypeChange = (newState) => {
    const newType = articleType === LANDING_PAGE_ADVERTORIAL_TYPES.LISTICLE ? LANDING_PAGE_ADVERTORIAL_TYPES.GENERAL : LANDING_PAGE_ADVERTORIAL_TYPES.LISTICLE
    setArticleType(newType)
    inputs[KEYS.ARTICLE_TYPE] = newType
  };

  const handlePovChange = (newState) => {
    const newPov = pov === POV_TYPES.FIRST ? POV_TYPES.THIRD : POV_TYPES.FIRST
    setPov(newPov)
    inputs[KEYS.POV] = newPov
  };

  return (
    <>
      <Box pt="40px">
        <Typography variant="h3" component="h3" fontSize={28} margin="auto">
          What type of landing page advertorial is this?
        </Typography>
      </Box>
      <Box pl="24px" pt="24px" pb="24px">
        <FormLabel component="legend">Article Type</FormLabel>
        <DoubleLabeledSwitch
          checked={articleType === LANDING_PAGE_ADVERTORIAL_TYPES.LISTICLE}
          onChange={handleArticleTypeChange}
          leftLabel="General advertorial"
          rightLabel="Listicle"
        />
      </Box>
      <Box pl="24px" pb="24px">
        <FormLabel component="legend">Point of View</FormLabel>
        <DoubleLabeledSwitch
          checked={pov === POV_TYPES.FIRST}
          onChange={handlePovChange}
          leftLabel="Third person"
          rightLabel="First person"
        />
      </Box>
    </>
  );
}
