import {
  Autocomplete,
  Box,
  Button,
  Container,
  TextField,
  Typography
} from "@mui/material"
import {useTheme} from '@mui/material/styles'
import * as React from "react";
import {COMPANY_DEFAULT_VALUES, COMPANY_NAMES, requiredKeys} from "../../libs/company-data";
import {INPUTS_ZERO_STATE} from "./creative-parent-form";
import CopyTypeSelector from "./copy-type-selector";
import {CONTAINER_SX} from "../../libs/constants";

export default function HomeCreative({nextStep, goToRunStep, handleChange, loadInputs, inputs}) {

  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      nextStep()
    }
  }

  const handleAutocompleteSelect = (event, value) => {
    // Only load default company values if the user clicks on an autocomplete option (i.e. don't load on keystrokes)
    if (event.type === "click") {
      if (value in COMPANY_DEFAULT_VALUES) {
        loadInputs(COMPANY_DEFAULT_VALUES[value])
      } else {
        // Reset inputs if the user clicks the Clear button
        loadInputs(INPUTS_ZERO_STATE)
      }
    } else {
      handleChange("companyName")({
        target: {
          value: value
        }
      })
    }
  }

  return (
    <Box id="home" onKeyDown={handleKeyDown} tabIndex="1">
      <Box sx={{
        position: "relative",
        ...CONTAINER_SX
      }}>
        <Box sx={{
          display: "flex",
          width: "100%",
          position: "absolute",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          zIndex: 0
        }}>
          <img src="/purple-blob.svg" alt="purple blob"/>
        </Box>
        <Box sx={{
          bottom: 0,
          position: "absolute",
          maxHeight: "292px",
          zIndex: 0
        }}>
          <img src="/orange-blob.svg" alt="orange blob"/>
        </Box>
        <Container id="home-container-top"
                   maxWidth="false"
                   sx={{
                     backgroundColor: theme.palette.primary.main,
                     paddingTop: "110px",
                   }}>
          <Box display="flex">
            <Typography variant="h3" component="h1" fontSize={56} margin="auto" sx={{fontFamily: "Tangerine"}}>
              Welcome back!
            </Typography>
          </Box>
          <Box display="flex" pt="12px">
            <Box m="auto" pt="30px">
              <img src="/mr-oven.png" alt="mr oven" width="400"/>
            </Box>
          </Box>
          <Box display="flex" pt="42px">
            <Typography variant="h4" component="h1" fontSize={28} fontWeight={400} margin="auto">
              What type of copy do you need?
            </Typography>
          </Box>
          <Box display="flex" pt="24px">
            <Box m="auto">
              {CopyTypeSelector(inputs.copyType, handleChange)}
              {/*{CopyTypeSelectButtons(inputs.copyType, handleChange)}*/}
            </Box>
          </Box>
          <Box display="flex" pt="60px">
            <Typography variant="h4" component="h1" fontSize={28} fontWeight={400} margin="auto">
              What company is this for?
            </Typography>
          </Box>
          <Box display="flex" pt="24px">
            <Box m="auto" component="form">
              <Autocomplete
                id="company-textfield"
                freeSolo
                onChange={handleAutocompleteSelect}
                options={COMPANY_NAMES}
                renderInput={(params) =>
                  <TextField {...params}
                             variant="standard"
                             placeholder="MeUndies"
                             value={inputs.companyName}
                             onChange={handleChange("companyName")}
                             onKeyDown={handleKeyDown}
                             autoFocus
                             sx={{
                               '& .MuiInput-root': {
                                 paddingX: "43px",
                                 color: theme.palette.primary.black,
                                 background: theme.palette.primary.white,
                                 border: "3px solid black",
                                 borderRadius: "18px",
                                 fontWeight: 400,
                                 fontSize: "24px",
                                 lineHeight: "26px",
                                 height: "65px",
                               },
                               '& .MuiInput-underline:before': {
                                 // Remove underline in default state
                                 borderBottom: 'none',
                               },
                               '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                 // Remove underline on hover
                                 borderBottom: 'none',
                               },
                               '& .MuiInput-underline:after': {
                                 // Remove underline on focus
                                 borderBottom: 'none',
                               },
                               '& .MuiAutocomplete-endAdornment': {
                                 // Move clear icon to the left
                                 right: '20px',
                               },
                               width: "500px",
                             }}
                  />
                }
              />
              {/*This hidden input field is a hack to prevent the Enter key from submitting the form and reloading the page*/}
              <input hidden type="text"/>
            </Box>
          </Box>
          <Box display="flex" pt="70px" pb="100px">
            <Box margin="auto">
              <Button variant="contained"
                      onClick={goToRunStep}
                      color="secondary"
                      disabled={!requiredKeys.every(key => {
                        const value = inputs[key];
                        if (Array.isArray(value)) {
                          return value.length > 0
                        }
                        return value != null && value !== ''
                      })}
                      sx={{
                        boxShadow: 10,
                        marginRight: "25px",
                        backgroundColor: theme.palette.primary.pink,
                      }}>
                Run
              </Button>
              <Button variant="contained"
                      onClick={nextStep}
                      color="secondary"
                      disabled={!inputs.companyName}
                      sx={{
                        boxShadow: 10,
                        width: "175px",
                      }}>
                Continue
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
