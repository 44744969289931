import {useTheme} from "@mui/material/styles";
import {Box, Fade, Typography} from "@mui/material";
import {Cancel} from "@mui/icons-material";

const Tags = ({
                data,
                handleDelete,
                highlightFirst,
                index,
                columnMode = false,
              }) => {
  const theme = useTheme()

  return (
    <Fade direction={"down"}
           timeout={250}
           in={true}>
      <Box
        sx={{
          ...((highlightFirst && index === 0) ? {background: theme.palette.primary.green} : {background: theme.palette.primary.black}),
          borderRadius: "8px",
          height: "100%",
          display: "flex",
          padding: "9px 5px 0 8px",
          marginTop: "0.3rem",
          marginRight: "5px",
          marginLeft: columnMode ? "0.5rem" : "0",
          justifyContent: columnMode ? "left" : "center",
          alignContent: columnMode ? "left" : "center",
          color: theme.palette.primary.white,
          cursor: "pointer",
        }}
        onClick={() => {
          handleDelete(data);
        }}
      >
        <Box sx={{flexGrow: 1, marginRight: '8px'}}>
          <Typography>{data}</Typography>
        </Box>
        <Box sx={{flexGrow: 0}}>
          <Cancel/>
        </Box>
      </Box>
    </Fade>
  )
}

export default Tags