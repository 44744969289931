import React  from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  Slide,
  Typography
} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import WindowTopElements from "../base/window-top-elements";
import InputColumn from "../base/input-column";
import {KEYS} from "../../libs/company-data";
import {CONTAINER_SX, COPY_TYPES, isCopyTypeUGC} from "../../libs/constants";
import landingPagePrompts from "./optional-landing-page-prompts";

export default function OptionalFieldsCreativeForm({nextStep, prevStep, handleChange, inputs, direction}) {
  const theme = useTheme()

  // Go to the next step if Enter is pressed
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      nextStep()
    }
  }

  const getOptionalPrompt = () => {
    const copyType = inputs[KEYS.COPY_TYPE];
    if (isCopyTypeUGC(copyType)) {
      return toneOfVoicePrompt()
    }
    if (copyType === COPY_TYPES.LANDING_PAGE_ADVERTORIAL) {
      return landingPagePrompts(inputs)
    }
    console.log("Copy type does not have optional fields. This should not happen.")
    return null
  }

  const toneOfVoicePrompt = () => (
    <>
      <Box pt="40px">
        <Typography variant="h3" component="h3" fontSize={28} margin="auto">
          What's the desired tone of voice?
        </Typography>
      </Box>
      <Box pl="24px" pb="20px"/>
      <InputColumn inputs={inputs}
                   inputKey="tone"
                   handleChange={handleChange}
                   placeholder='"conversational", "witty", "persuasive", "organic", "authentic"'
                   objectName="adjectives"
                   autoFocus
      />
      <Box pt="24px"/>
    </>
  )

  return (
    <Container id="optional-fields-form-container"
               sx={CONTAINER_SX}>
      <Box sx={{opacity: 0}}>
        <input onKeyDown={handleKeyDown} autoFocus/>
      </Box>
      <Box pt="40px" pl="24px">
        <Typography variant="h2" component="h1" fontSize={36} fontWeight={500} margin="auto">
          Optional Fields
        </Typography>
      </Box>
      <Slide direction={direction ? "right" : "left"}
             timeout={400}
             in={true}>
        <Box>
          <Paper elevation={3}
                 sx={{
                   marginTop: "48px",
                   border: "2px solid black",
                   borderRadius: "16px"
                 }}>
            <WindowTopElements/>
            <Container maxWidth="lg">
              {getOptionalPrompt()}
            </Container>
          </Paper>
          <Box display="flex" pt="46px" pb="100px">
            <Box margin="auto">
              <Button variant="outlined"
                      onClick={prevStep}
                      color="primary"
                      sx={{
                        boxShadow: 10,
                        marginRight: "25px",
                        color: theme.palette.primary.black
                      }}>
                Back
              </Button>
              <Button variant="contained"
                      onClick={nextStep}
                      color="secondary"
                      sx={{
                        boxShadow: 10,
                        width: "175px",
                      }}>
                Run
              </Button>
            </Box>
          </Box>
        </Box>
      </Slide>
    </Container>
  )
}
