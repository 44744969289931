import {Auth} from "aws-amplify";
import {UGC_COPY_TYPES} from "./constants";

// create config object for Amplify (merges the objects created by Amplify pull and the one created by aws-amplify-serverless-plugin
export const getAwsConfig = (ampConfig, slsConfig) => ({
  ...slsConfig,
  // Amplify configurations spread after because they take priority over serverless configs
  ...ampConfig,
  // Below is a hack to dynamically set the Lambda endpoint URL depending on the environment
  // We do this because the endpoint URL isn't being written to 'serverless-exports.js' when 'sls deploy' is run sometimes
  "aws_cloud_logic_custom": [
    {
      "endpoint": process.env.REACT_APP_PROCESS_SEARCH_TERM_REPORT_LAMBDA_URL,
      "name": 'ApiGatewayRestApi',
      "region": 'us-west-2'
    },
  ],
})

export const getFileType = filename => filename.split('.').pop().toLowerCase();

// convert a number to a string representation of a decimal
export const convertToDecimalString = num => String(Number(num) / 100)


export const getAuthenticatedUserId = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes.sub; // 'sub' is the Cognito User ID
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const getRandomUGCCopyType = () => {
  const ugcKeys = Object.keys(UGC_COPY_TYPES);
  return ugcKeys[Math.floor(Math.random() * ugcKeys.length)]
}