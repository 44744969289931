import React, {useCallback} from "react";
import {Box, Container, Typography} from "@mui/material";
import {useTheme} from '@mui/material/styles'
import {FileUpload} from "@mui/icons-material";
import {useDropzone} from 'react-dropzone'
import {Storage} from "aws-amplify";

import {S3_ACCESS_LEVEL, TOAST_SEVERITY_STATUSES} from "../../libs/constants";
import {getFileType} from "../../libs/utils";

export default function DragDropFile({nextStep, createToast, setLoading, handleChange, inputKey}) {
  const theme = useTheme()

  const onDrop = useCallback(acceptedFiles => {
    // Validate only 1 file was uploaded
    if (acceptedFiles.length > 1) {
      createToast("Please upload 1 file at a time", TOAST_SEVERITY_STATUSES.ERROR)
      return
    }

    acceptedFiles.forEach(file => {
      const filename = file.name;
      // Validate CSV file
      const fileType = getFileType(filename)
      if (fileType !== "csv") {
        createToast("Please upload CSV files only", TOAST_SEVERITY_STATUSES.ERROR)
        return
      }

      setLoading(true)
      const uploadFilepath = 'inputs/'.concat(filename)
      try {
        Storage.put(uploadFilepath, file, {
          level: S3_ACCESS_LEVEL,
          contentType: 'text/csv'
        }).then(() => {
          console.log(`${filename} uploaded to S3`)
          handleChange(inputKey)({
            target: {
              value: filename
            }
          })
          createToast(`Uploaded ${filename}`, TOAST_SEVERITY_STATUSES.SUCCESS)
          setLoading(false)
          nextStep()
        })
      } catch (err) {
        createToast(`Failed to upload ${filename}`, TOAST_SEVERITY_STATUSES.ERROR)
        console.log(err);
      }
    })
  }, [createToast, nextStep, setLoading, handleChange, inputKey])

  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Container id="search-term-report-upload-container"
                 maxWidth="sm"
                 sx={{
                   paddingY: "86px",
                   border: "1px dashed #000000",
                   borderRadius: "18px",
                   backgroundColor: theme.palette.primary.white,
                 }}
      >
        <Box display="flex">
          <Box m="auto">
            <FileUpload sx={{width: "60px", height: "60px"}}/>
          </Box>
        </Box>
        <Box display="flex" pt="20px">
          <Typography variant="h5" component="h2" margin="auto">
            Upload
          </Typography>
        </Box>
        <Box display="flex" pt="8px">
          <Typography variant="subtitle1" component="h2" margin="auto" color="#5D6A7E">
            Drag a CSV file here
          </Typography>
        </Box>
      </Container>
    </div>
  )
}
